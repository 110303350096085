<div class="news-title-container score-focus">
  <div
    class="news-title news-title-suppliers score-focus"
    *ngIf="networkEntityName && titolo"
  >
    <div class="cont">
      <div class="cont-left">
        <ng-container>
          <div class="text" style="display: flex; align-items: center">
            <span class="tipo"
              ><span style="font-weight: normal">Supply chain:</span>
            </span>
            <mat-select
              [value]="selectedSuplier"
              (selectionChange)="selectSupplier($event.value)"
            >
              <mat-option
                *ngFor="let option of parentSupliers"
                [value]="option.value"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="news-title news-title-filters score-focus" *ngIf="titolo">
    <div class="filter-box">
      <div class="filter-box-label">ESG Score</div>
      <div class="filter-box-input">
        <mat-slider
          [(ngModel)]="suppliersFilters.score"
          (input)="suppliersFilters.score = $event.value"
          (valueChange)="filterSuppliers($event, 'score')"
          min="0"
          max="5"
          step="1"
          [displayWith]="sliderLabel"
        ></mat-slider>
      </div>
    </div>

    <div class="filter-box">
      <mat-form-field appearance="outline">
        <mat-label>Product categories</mat-label>
        <mat-select
          [(ngModel)]="suppliersFilters.product"
          (selectionChange)="filterSuppliers($event.value, 'product')"
        >
          <mat-option value="All">All</mat-option>
          <mat-option
            *ngFor="let option of productFilter | orderBy"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter-box">
      <mat-form-field appearance="outline">
        <mat-label>Geography</mat-label>
        <mat-select
          [(ngModel)]="suppliersFilters.geography"
          (selectionChange)="filterSuppliers($event.value, 'geography')"
        >
          <mat-option value="All">All</mat-option>
          <mat-option
            *ngFor="let option of geographyFilter | orderBy"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter-box">
      <mat-form-field appearance="outline">
        <mat-label>Tier</mat-label>
        <mat-select
          [(ngModel)]="suppliersFilters.tier"
          (selectionChange)="filterSuppliers($event.value, 'tier')"
        >
          <mat-option value="All">All</mat-option>
          <mat-option
            *ngFor="let option of tierFilter | orderBy"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
