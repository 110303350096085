/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  OnInit,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, startWith, debounceTime, mergeMap, take } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyRadioGroup as MatRadioGroup } from '@angular/material/legacy-radio';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';
import { ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

export class Event {
  constructor(
    public id: string,
    public name: string,
    public category: string,
    public categoryId: string,
    public selected?: boolean,
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}

export class EventCategory {
  constructor(
    public id: string,
    public name: string,
    public selected?: boolean,
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-alerts-setup',
  templateUrl: './alerts-setup.component.html',
  styleUrls: ['./alerts-setup.component.scss'],
})
export class AlertsSetupComponent implements OnInit, OnChanges {
  @ViewChild('selectorAlertSchedule', { static: false })
  scheduleField: MatSelect;
  @ViewChild('selectorAlertChannel', { static: false }) channelField: MatSelect;
  @ViewChild('selectorAlertType', { static: false })
  alertTypeField: MatRadioGroup;
  @ViewChild('selectorAlertObject', { static: false })
  alertObjectSelectorField: MatSelect;
  @ViewChild('inputAlertObject', { static: false, read: ElementRef })
  alertObjectInputField: ElementRef;

  @ViewChildren('selectorTriggerScore')
  triggerScoreFields: QueryList<MatSelect>;
  @ViewChildren('selectorTriggerThresholdSetting')
  triggerThresholdSettingFields: QueryList<MatSelect>;
  @ViewChildren('inputTriggerThreshold')
  triggerThresholdFields: QueryList<ElementRef>;

  @ViewChild('selectorEventsScore', { static: false })
  scoreTypeField: MatSelect;
  @ViewChild('inputEvents', { static: false, read: ElementRef })
  eventsInputField: ElementRef;

  @ViewChildren(MatAutocompleteTrigger)
  autocompleteTriggers: QueryList<MatAutocompleteTrigger>;

  isButtonActive = false;

  fieldsSeen = true;

  currentAlert: any;
  alerts = [];

  notitle = false;

  scoreTypes = [];
  scoreEventsTypes = [];
  thresholdSettingTypes = [];

  selectedTabIndex = 0;
  tabNames = ['Score Alerts', 'Event Alerts'];

  items$: Observable<any>;
  folio$: Observable<any>;

  folio: any;

  loading: boolean;

  loadingAlerts: boolean;

  searchFormControl = new UntypedFormControl();

  eventsControl = new UntypedFormControl();

  subEvents = {};
  subEventsIds = {};
  eventsCategories = {};
  eventsCategoriesIds = {};
  eventsList: any;
  eventsGroupsOptions: any;

  scoreEvents = [];
  scoreCategories = [];

  events = [];
  selectedEvents: Event[] = new Array<Event>();
  selectedEventsCopy: Event[] = new Array<Event>();
  filteredEvents: Observable<Event[]>;
  strictFiltering = false;

  eventCategories = [];
  selectedCategories: EventCategory[] = new Array<EventCategory>();
  filteredEventCategories: Observable<EventCategory[]>;

  closeAfterSelection: boolean;

  lastFilter = '';

  user: any;
  domain: string;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER];
  emailList = [];

  selectedDeltaPeriod = 2;
  deltaPeriods: number[] = Array.from({ length: 29 }, (_, i) => i + 2);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    private yukkApi: YukkApi,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private config: ConfigService,
  ) {
    this.user = auth.authToken();
    this.domain = auth.authToken().email.split('@')[1];
  }

  ngOnInit() {
    this.initializeScoreTypes();
    this.initializeSettings();
    this.initializeEventsForms();
    this.initializeAlerts();
    this.initializePortfolios();
  }

  ngOnChanges() {
    if (this.currentAlert) {
      this.updateForms();
    }
  }

  private addScoreType(
    access: boolean,
    value: string,
    label: string,
    eventLabel: string = label,
  ) {
    if (access) {
      this.scoreTypes.push({ value, label });
      this.scoreEventsTypes.push({ value, label: eventLabel });
    }
  }

  private initializeScoreTypes() {
    const settings = this.auth.scorelabSettings;
    this.addScoreType(
      settings.sentimentAccess,
      'sentiment',
      'Sentiment',
      'All',
    );
    this.addScoreType(
      settings.generalRiskAccess,
      'general_risk',
      'General Risk Score',
    );
    this.addScoreType(
      settings.creditRiskAccess,
      'credit_risk',
      'Credit Risk Score',
    );
    this.addScoreType(settings.esgRiskAccess, 'esg_risk', 'ESG Risk Score');
    this.addScoreType(
      settings.immediateRiskAccess,
      'immediate_risk',
      'Immediate Risk Score',
    );
    this.addScoreType(settings.esgAccess, 'esg', 'ESG Score');
    if (settings.eb_simAccess) {
      this.addScoreType(true, 'eb_sim', 'EB-SIM Score');
      this.addScoreType(true, 'eb_sim_risk', 'EB-SIM Risk Score');
    }
    if (settings.bbAccess) {
      this.scoreTypes.push({ value: 'bb', label: 'Bull-Bear Score' });
    }
    if (settings.customScoreAccess && settings.customScores) {
      settings.customScores.forEach((item) => {
        this.scoreTypes.push({ value: item.uid, label: item.name });
        this.scoreEventsTypes.push({ value: item.uid, label: item.name });
      });
    }
  }

  private initializeSettings() {
    this.loadingAlerts = true;
    this.closeAfterSelection = false;
    this.notitle = false;
    this.fieldsSeen = true;
    if (!this.auth.featureFlags.showEventAlerts) {
      this.tabNames = ['Score Alerts'];
    }
    this.user = this.auth.authToken();
    if (
      this.user.roles?.includes('ADMIN') ||
      this.user.groups?.includes('ADMIN')
    ) {
      this.thresholdSettingTypes.push({
        value: 'pass_through',
        label: 'Pass Through',
      });
    }
  }

  private initializeEventsForms() {
    this.eventsList = JSON.parse(JSON.stringify(this.auth.eventsList));

    this.eventsGroupsOptions = this.eventsList.events.map((el) => {
      this.eventsCategories[el.value] = el.label;
      this.eventsCategoriesIds[el.label] = el.value;

      const events = this.eventsList.subEvents[el.value].map((item) => {
        return item.label;
      });
      return {
        label: el.label,
        events: events,
      };
    });

    Object.entries(this.eventsList.subEvents).forEach(([key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      value.forEach((item) => {
        this.subEvents[item.value] = item.label;
        this.subEventsIds[item.label] = item.value;

        this.events.push(
          new Event(
            item.value,
            item.label,
            this.eventsCategories[key],
            key.toString(),
          ),
        );
      });
    });

    this.eventsList.events.forEach((event) => {
      this.eventCategories.push(
        new EventCategory(event['value'], event['label']),
      );
    });

    this.filteredEvents = this.eventsControl.valueChanges.pipe(
      startWith<string | Event[]>(''),
      map((value) => (typeof value === 'string' ? value : this.lastFilter)),
      map((filter) => this.filterEvents(filter)),
    );

    this.filteredEventCategories = this.eventsControl.valueChanges.pipe(
      startWith<string | EventCategory[]>(''),
      map((value) => (typeof value === 'string' ? value : this.lastFilter)),
      map((filter) => this.filterCategories(filter)),
    );
  }

  private initializeAlerts() {
    this.currentAlert = this.createDefaultScoreAlert();

    forkJoin([
      this.auth.getAlerts('score'),
      this.auth.getAlerts('event'),
    ]).subscribe(
      (resArray) => {
        const resScore = resArray[0].hits.map((el) =>
          Object.assign(
            {},
            el,
            { alert_category: 'scores' },
            el.score_alert_metadata,
          ),
        );
        const resEvent = resArray[1].hits.map((el) =>
          Object.assign(
            {},
            el,
            { alert_category: 'events' },
            el.event_alert_metadata,
          ),
        );
        const result = resScore.concat(resEvent);
        result.forEach((item) => {
          const alertObject = {
            alert_category: '',
            alert_type: '',
            object_type: '',
            object_id: '',
            object_name: '',
            score_type: '',
            threshold: '',
            threshold_setting: '',
            events: [],
            triggers: [],
            additional_recipients: [],
          };
          alertObject.alert_category = item.alert_category;
          alertObject.alert_type = item.portfolio_id ? 'portfolio' : 'entity';
          alertObject.object_type = item.portfolio_id
            ? 'portfolio'
            : item.entities[0].split(':')[0];
          alertObject.object_id = item.portfolio_id
            ? item.portfolio_id
            : item.entities[0].split(':')[1];
          alertObject.additional_recipients = item.additional_recipients;
          if (alertObject.alert_category === 'events') {
            // alertObject.score_type = item.score_type;
            alertObject.score_type = 'sentiment';
            alertObject.threshold = item.threshold;
            alertObject.threshold_setting = item.threshold_setting;
            const events = [...new Set(item.event_ids)];
            alertObject.events = events
              .filter((e) => this.events.filter((el) => el.id === e).length > 0)
              .map((e: any) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return new Event(
                  e,
                  this.subEvents[e],
                  this.events.filter((el) => el.id === e)[0].category,
                  this.events.filter((el) => el.id === e)[0].categoryId,
                );
              });
          } else if (alertObject.alert_category === 'scores') {
            if (alertObject.alert_type === 'portfolio') {
              const triggerObject = {
                score_type: '',
                threshold: '',
                threshold_setting: '',
                isDeltaAlert: false,
                additional_params: {
                  z_score_period: '7',
                  z_score_direction: '',
                  z_score_threshold: '',
                  operator: '',
                  plateau_period: '0',
                },
              };
              triggerObject.score_type = item.triggers[0]['score_type'];
              if (triggerObject.score_type === 'credit_watch') {
                triggerObject.score_type = 'credit_risk';
              }
              triggerObject.threshold_setting =
                item.triggers[0].threshold_setting ===
                'z_score_unnormalized_threshold'
                  ? item.triggers[0].additional_params.operator
                  : item.triggers[0].threshold_setting;
              triggerObject.threshold =
                triggerObject.score_type === 'sentiment'
                  ? parseFloat(item.triggers[0].threshold) * 100.0
                  : item.triggers[0].threshold;
              triggerObject.isDeltaAlert =
                item.triggers[0].threshold_setting ===
                'z_score_unnormalized_threshold';
              triggerObject.additional_params.z_score_period =
                item.triggers[0]['additional_params']['z_score_period'];
              triggerObject.additional_params.z_score_direction =
                item.triggers[0]['additional_params']['z_score_direction'];
              triggerObject.additional_params.z_score_threshold =
                item.triggers[0]['additional_params']['z_score_threshold'];
              triggerObject.additional_params.operator =
                item.triggers[0]['additional_params']['operator'];
              triggerObject.additional_params.plateau_period =
                item.triggers[0]['additional_params']['plateau_period'];
              alertObject.triggers.push(
                Object.assign({}, item.triggers[0], triggerObject),
              );
            } else {
              item.triggers.forEach((trigger) => {
                const triggerObject = {
                  score_type: '',
                  threshold: '',
                  threshold_setting: '',
                  isDeltaAlert: false,
                  additional_params: {
                    z_score_period: '7',
                    z_score_direction: '',
                    z_score_threshold: '',
                    operator: '',
                    plateau_period: '0',
                  },
                };
                triggerObject.score_type = trigger['score_type'];
                if (triggerObject.score_type === 'credit_watch') {
                  triggerObject.score_type = 'credit_risk';
                }
                triggerObject.threshold_setting =
                  trigger.threshold_setting === 'z_score_unnormalized_threshold'
                    ? trigger.additional_params.operator
                    : trigger.threshold_setting;
                triggerObject.threshold =
                  triggerObject.score_type === 'sentiment'
                    ? parseFloat(trigger.threshold) * 100.0
                    : trigger.threshold;
                triggerObject.isDeltaAlert =
                  trigger.threshold_setting ===
                  'z_score_unnormalized_threshold';
                triggerObject.additional_params.z_score_period =
                  trigger['additional_params']['z_score_period'];
                triggerObject.additional_params.z_score_direction =
                  trigger['additional_params']['z_score_direction'];
                triggerObject.additional_params.z_score_threshold =
                  trigger['additional_params']['z_score_threshold'];
                triggerObject.additional_params.operator =
                  trigger['additional_params']['operator'];
                triggerObject.additional_params.plateau_period =
                  trigger['additional_params']['plateau_period'];
                alertObject.triggers.push(
                  Object.assign({}, trigger, triggerObject),
                );
              });
            }
          }
          this.alerts.push(Object.assign({}, item, alertObject));
        });
        if (this.alerts.length > 0) {
          if (
            this.alerts.filter((a) => a.alert_category === 'scores').length > 0
          ) {
            this.currentAlert = this.alerts.filter(
              (a) => a.alert_category === 'scores',
            )[0];
          } else if (
            this.alerts.filter((a) => a.alert_category === 'events').length > 0
          ) {
            if (this.selectedTabIndex === 0) {
              this.currentAlert = this.createDefaultScoreAlert();
            } else {
              this.currentAlert = this.alerts.filter(
                (a) => a.alert_category === 'events',
              )[0];
              this.updateSelectedEvents();
            }
          } else {
            this.currentAlert = this.alerts[0];
          }
        }
        this.updateForms();

        this.loadingAlerts = false;
      },
      () => {
        this.loadingAlerts = false;
      },
    );
  }

  private initializePortfolios() {
    this.folio$ = this.auth.portFolios();
    this.folio$.subscribe((result) => {
      this.folio = result;
    });
  }

  filterEvents(filter: string): Event[] {
    this.lastFilter = filter;
    if (filter) {
      return this.events.filter((option) => {
        // return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        return (
          option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          option.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        );
      });
    } else {
      return this.events.slice();
    }
  }

  filterCategories(filter: string): Event[] {
    this.lastFilter = filter;
    if (filter) {
      return this.eventCategories.filter((option) => {
        let isMatchingSubevent = false;
        this.eventsList.subEvents[option.id].forEach((el) => {
          if (el.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
            isMatchingSubevent = true;
          }
        });
        return (
          option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          isMatchingSubevent
        );
      });
    } else {
      return this.eventCategories.slice();
    }
  }

  removeEvent(event) {
    this.toggleEventSelection(event);
    if (
      this.filterCategories('').filter(
        (category) => event.categoryId === category.id,
      ).length > 0
    ) {
      this.filterCategories('').filter(
        (category) => event.categoryId === category.id,
      )[0].selected = false;
    }
  }

  displayFnEvents = (): string | undefined => {
    return this.lastFilter;
  };

  unselectGroup(group: EventCategory) {
    group.selected = false;
  }

  toggleEventSelection(event: Event) {
    event.selected = !event.selected;
    if (event.selected) {
      this.selectedEvents.push(event);
    } else {
      const i = this.selectedEvents.findIndex(
        (value) =>
          value.name === event.name && value.category === event.category,
      );
      this.selectedEvents.splice(i, 1);
    }

    this.eventsControl.setValue(this.selectedEvents);
  }

  toggleEventGroupSelection(group: EventCategory, filtered) {
    filtered.pipe(take(1)).subscribe((res) => {
      group.selected = !group.selected;
      if (group.selected) {
        this.eventsList.subEvents[group.id]
          .filter((e) => this.scoreEvents.includes(e.value))
          .forEach((event) => {
            if (
              this.selectedEvents.filter((e) => e.id === event.value).length ===
              0
            ) {
              if (res.filter((e) => e.id === event.value).length !== 0) {
                const eventCopy = res.filter((e) => e.id === event.value)[0];
                eventCopy.selected = true;
                this.selectedEvents.push(eventCopy);
              }
            }
          });
      } else {
        this.eventsList.subEvents[group.id]
          .filter((e) => this.scoreEvents.includes(e.value))
          .forEach((event) => {
            if (
              this.selectedEvents.filter((e) => e.id === event.value).length !==
              0
            ) {
              if (res.filter((e) => e.id === event.value).length !== 0) {
                const eventCopy = res.filter((e) => e.id === event.value)[0];
                eventCopy.selected = false;
                const i = this.selectedEvents.findIndex(
                  (el) => el.id === event.value,
                );
                this.selectedEvents.splice(i, 1);
              }
            }
          });
      }
      this.eventsControl.setValue(this.selectedEvents);
    });
  }

  onTabSelection(event) {
    this.notitle = false;
    this.fieldsSeen = true;
    if (event === 0) {
      this.selectedTabIndex = 0;
      if (this.alerts.filter((a) => a.alert_category === 'scores').length > 0) {
        this.currentAlert = this.alerts.filter(
          (a) => a.alert_category === 'scores',
        )[0];
      } else {
        this.currentAlert = this.createDefaultScoreAlert();
      }
    } else if (event === 1) {
      this.selectedTabIndex = 1;
      if (this.alerts.filter((a) => a.alert_category === 'events').length > 0) {
        this.currentAlert = this.alerts.filter(
          (a) => a.alert_category === 'events',
        )[0];
        this.updateSelectedEvents();
      } else {
        this.currentAlert = this.createDefaultEventAlert();
        this.updateSelectedEvents();
      }
    }
    this.updateForms();
  }

  updateSelectedEvents() {
    const scoreType = this.currentAlert.score_type;
    if (scoreType) {
      this.yukkApi.scoreWeights({}, scoreType).subscribe((response) => {
        const res = JSON.parse(JSON.stringify(response));
        if (scoreType === 'sentiment') {
          this.strictFiltering = false;
          this.scoreEvents = JSON.parse(
            JSON.stringify(this.events.map((el) => el.id)),
          );
        } else {
          this.strictFiltering = true;
          this.scoreEvents = res;
        }
        this.scoreCategories = [
          ...new Set(
            this.events
              .filter((el) => this.scoreEvents.includes(el.id))
              .map((el) => el.categoryId),
          ),
        ];
        this.selectedEvents = [];
        this.eventsControl.setValue(this.selectedEvents);
        this.events.forEach((el) => {
          el.selected = false;
        });
        this.eventCategories.forEach((el) => {
          el.selected = false;
        });
        this.events.forEach((el) => {
          if (
            this.currentAlert.events.filter((e) => e.id === el.id).length > 0
          ) {
            this.toggleEventSelection(el);
          }
        });
        this.eventsControl.setValue(this.selectedEvents);
      });
    } else {
      this.selectedEvents = [];
      this.eventsControl.setValue(this.selectedEvents);
      this.events.forEach((el) => {
        el.selected = false;
      });
      this.eventCategories.forEach((el) => {
        el.selected = false;
      });
      this.scoreEvents = [];
      this.scoreCategories = [];
      this.strictFiltering = false;
    }
  }

  updateSelectedEventsWithCopy() {
    this.selectedEvents = [];
    this.eventsControl.setValue(this.selectedEvents);
    this.events.forEach((el) => {
      el.selected = false;
    });
    this.eventCategories.forEach((el) => {
      el.selected = false;
    });
    this.events.forEach((el) => {
      if (this.selectedEventsCopy.filter((e) => e.id === el.id).length > 0) {
        this.toggleEventSelection(el);
      }
    });
    this.eventsControl.setValue(this.selectedEvents);
  }

  selectAlert(alert) {
    this.notitle = false;
    this.fieldsSeen = true;
    const copyAlert = JSON.parse(JSON.stringify(alert));
    this.currentAlert = copyAlert;
    if (this.currentAlert.alert_category === 'events') {
      this.updateSelectedEvents();
    }
    this.updateForms();
  }

  onSlideToggle(isToggled, alert) {
    if (isToggled) {
      this.activateAlert(alert);
    } else {
      this.deactivateAlert(alert);
    }
  }

  onDeltaSlideToggle(isDeltaActive, trigger) {
    if (isDeltaActive) {
      trigger.isDeltaAlert = true;
      if (trigger.threshold_setting === 'cross_above') {
        trigger.threshold_setting = '>=';
      } else if (trigger.threshold_setting === 'cross_below') {
        trigger.threshold_setting = '<=';
      } else if (!['>=', '<='].includes(trigger.threshold_setting)) {
        trigger.threshold_setting = '';
      }
    } else {
      trigger.isDeltaAlert = false;
      if (trigger.threshold_setting === '>=') {
        trigger.threshold_setting = 'cross_above';
      } else if (trigger.threshold_setting === '<=') {
        trigger.threshold_setting = 'cross_below';
      }
    }
  }

  activateAlert(alert) {
    this.notitle = false;
    this.fieldsSeen = true;
    this.currentAlert = alert;
    this.saveAlert(true, true);
  }

  deactivateAlert(alert) {
    this.notitle = false;
    this.fieldsSeen = true;
    this.currentAlert = alert;
    this.saveAlert(false, false);
  }

  createAlert() {
    this.notitle = false;
    this.fieldsSeen = false;
    if (this.selectedTabIndex === 0) {
      this.currentAlert = this.createDefaultScoreAlert();
    } else if (this.selectedTabIndex === 1) {
      this.currentAlert = this.createDefaultEventAlert();
      this.updateSelectedEvents();
    }
    this.updateForms();
  }

  private createDefaultScoreAlert(): any {
    return {
      uid: null,
      name: '',
      active: false,
      alert_type: 'entity',
      alert_category: 'scores',
      schedule: '1d',
      channel: 'email',
      layout: 'digest',
      object_type: '',
      object_id: '',
      object_name: '',
      triggers: [
        {
          score_type: '',
          threshold: '',
          threshold_setting: '',
          isDeltaAlert: false,
          additional_params: {
            z_score_period: '7',
            z_score_direction: '',
            z_score_threshold: '',
            operator: '',
            plateau_period: '0',
          },
        },
      ],
      additional_recipients: [],
    };
  }

  private createDefaultEventAlert(): any {
    return {
      uid: null,
      name: '',
      active: false,
      alert_type: 'entity',
      alert_category: 'events',
      schedule: '1d',
      channel: 'email',
      layout: 'digest',
      object_type: '',
      object_id: '',
      object_name: '',
      score_type: 'sentiment',
      threshold: 5,
      threshold_setting: 'z_score',
      events: [],
      additional_recipients: [],
    };
  }

  isValidAlert(alert, focus = true) {
    if (alert) {
      let isValid = true;
      if (!alert.name.trim()) {
        this.notitle = true;
        isValid = false;
      }
      if (!alert.alert_type) {
        if (this.alertTypeField) {
          // this.alertTypeField.focus();
        }
        isValid = false;
      }
      if (!alert.schedule) {
        if (this.scheduleField && focus) {
          this.scheduleField.focus();
        }
        isValid = false;
      }
      if (!alert.layout) {
        if (this.channelField && focus) {
          this.channelField.focus();
        }
        isValid = false;
      }
      if (!alert.object_type || !alert.object_id) {
        if (this.alertObjectSelectorField && focus) {
          this.alertObjectSelectorField.focus();
        }
        if (this.alertObjectInputField && focus) {
          this.alertObjectInputField.nativeElement.focus();
        }
        isValid = false;
      }
      if (alert.alert_category === 'scores') {
        const alertTriggers = {
          scores: this.triggerScoreFields.toArray(),
          thresholdSettings: this.triggerThresholdSettingFields.toArray(),
          thresholds: this.triggerThresholdFields.toArray(),
        };
        alert.triggers.forEach((trigger, index) => {
          if (!trigger.score_type) {
            if (focus) {
              alertTriggers.scores[index].focus();
            }
            isValid = false;
          }
          if (!trigger.threshold && !(trigger.threshold === 0)) {
            if (focus) {
              alertTriggers.thresholds[index].nativeElement.focus();
            }
            isValid = false;
          }
          if (!trigger.threshold_setting) {
            if (focus) {
              alertTriggers.thresholdSettings[index].focus();
            }
            isValid = false;
          }
          if (trigger.isDeltaAlert) {
            if (!trigger.additional_params.z_score_period) {
              isValid = false;
            }
            if (!trigger.additional_params.z_score_direction) {
              isValid = false;
            }
            if (!trigger.additional_params.z_score_threshold) {
              isValid = false;
            }
            if (!trigger.additional_params.plateau_period) {
              isValid = false;
            }
          }
        });
      } else if (alert.alert_category === 'events') {
        if (!alert.score_type) {
          if (focus) {
            this.scoreTypeField.focus();
          }
          isValid = false;
        }
        if (!(this.selectedEvents && this.selectedEvents.length > 0)) {
          if (focus) {
            this.eventsInputField.nativeElement.focus();
          }
          isValid = false;
        }
        if (!alert.threshold && !(alert.threshold === 0)) {
          isValid = false;
        }
        if (!alert.threshold_setting) {
          isValid = false;
        }
      }
      return isValid;
    } else {
      return false;
    }
  }

  saveAlert(isActive, isActivated) {
    this.notitle = false;
    this.currentAlert.additional_recipients = this.emailList;
    if (
      this.isValidAlert(this.currentAlert) &&
      ((this.fieldsSeen && this.selectedTabIndex === 1) ||
        this.selectedTabIndex === 0)
    ) {
      this.currentAlert.active = isActive;
      const payloadObject = {};
      const payloadObjectMetadata = {};
      let isNew;
      if (this.currentAlert.uid === null) {
        isNew = true;
      } else {
        isNew = false;
      }
      this.createMainPayloadProperties(payloadObject);
      this.createThresholdPayloadProperties(payloadObjectMetadata);
      if (this.currentAlert.alert_category === 'scores') {
        payloadObject['score_alert_metadata'] = payloadObjectMetadata;
      }
      if (this.currentAlert.alert_category === 'events') {
        payloadObject['event_alert_metadata'] = payloadObjectMetadata;
      }
      this.storeAlert(payloadObject, isNew, isActivated);
      this.fieldsSeen = true;
    } else {
      if (
        (this.fieldsSeen && this.selectedTabIndex === 1) ||
        this.selectedTabIndex === 0
      ) {
        alert('Error: all of the required fields must be filled in.');
      }
    }
  }

  private createMainPayloadProperties(payloadObject) {
    if (this.currentAlert.uid) {
      payloadObject['uid'] = this.currentAlert.uid;
    }
    payloadObject['active'] = this.currentAlert.active;
    payloadObject['schedule'] = this.currentAlert.schedule;
    payloadObject['channel'] = 'email';
    payloadObject['layout'] = this.currentAlert.layout;
    payloadObject['name'] = this.currentAlert.name;
    payloadObject['additional_recipients'] =
      this.currentAlert.additional_recipients;
    if (this.currentAlert.alert_category === 'events') {
      this.currentAlert.events = this.selectedEvents;
    }
  }

  private createThresholdPayloadProperties(payloadObjectMetadata) {
    if (this.currentAlert.alert_type) {
      if (this.currentAlert.alert_type === 'entity') {
        payloadObjectMetadata['portfolio_id'] = null;
        payloadObjectMetadata['entities'] = [
          this.currentAlert.object_type + ':' + this.currentAlert.object_id,
        ];
      } else if (this.currentAlert.alert_type === 'portfolio') {
        payloadObjectMetadata['portfolio_id'] = this.currentAlert.object_id;
        payloadObjectMetadata['entities'] = [];
      }
      if (this.currentAlert.alert_type === 'portfolio') {
        if (this.currentAlert.alert_category === 'scores') {
          payloadObjectMetadata['triggers'] = [];
          const payloadObjectTrigger = {};
          if (this.currentAlert.triggers[0].score_type === 'sentiment') {
            payloadObjectTrigger['threshold'] =
              this.currentAlert.triggers[0].threshold / 100.0;
          } else {
            payloadObjectTrigger['threshold'] =
              this.currentAlert.triggers[0].threshold;
          }
          payloadObjectTrigger['threshold_setting'] = this.currentAlert
            .triggers[0].isDeltaAlert
            ? 'z_score_unnormalized_threshold'
            : this.currentAlert.triggers[0].threshold_setting;
          payloadObjectTrigger['score_type'] =
            this.currentAlert.triggers[0].score_type;
          payloadObjectTrigger['additional_params'] = {};
          payloadObjectTrigger['additional_params']['z_score_period'] =
            this.currentAlert.triggers[0].additional_params.z_score_period;
          payloadObjectTrigger['additional_params']['z_score_direction'] =
            this.currentAlert.triggers[0].additional_params.z_score_direction;
          payloadObjectTrigger['additional_params']['z_score_threshold'] =
            this.currentAlert.triggers[0].additional_params.z_score_threshold +
            '';
          payloadObjectTrigger['additional_params']['operator'] =
            this.currentAlert.triggers[0].threshold_setting;
          payloadObjectTrigger['additional_params']['plateau_period'] =
            this.currentAlert.triggers[0].additional_params.plateau_period;
          if (this.currentAlert.triggers[0].score_type === 'general_risk') {
            payloadObjectTrigger['additional_params'].with_black_swan = 'true';
          } else if (this.currentAlert.triggers[0].score_type === 'esg_risk') {
            payloadObjectTrigger['additional_params'].with_black_swan = 'true';
          } else if (
            this.currentAlert.triggers[0].score_type === 'immediate_risk'
          ) {
            payloadObjectTrigger['additional_params'].with_black_swan = 'true';
          } else if (
            this.currentAlert.triggers[0].score_type === 'credit_risk'
          ) {
            payloadObjectTrigger['additional_params'].with_black_swan = 'true';
            payloadObjectTrigger['additional_params'].risk_sensitivity = '0';
            payloadObjectTrigger['score_type'] = 'credit_watch';
          } else if (this.currentAlert.triggers[0].score_type === 'esg') {
            payloadObjectTrigger[
              'additional_params'
            ].disable_industry_materiality = 'true';
          } else if (this.currentAlert.triggers[0].score_type === 'bb') {
            payloadObjectTrigger['additional_params'].time_horizon = '1M';
          } else if (this.currentAlert.triggers[0].score_type) {
            this.auth.scorelabSettings.customScores.forEach((item) => {
              if (item.uid === this.currentAlert.triggers[0].score_type) {
                if (item.sentiment_type === 'neg') {
                  payloadObjectTrigger['additional_params']['with_black_swan'] =
                    'true';
                }
                if (item.event_weights && item.event_weights.length > 0) {
                  item.event_weights.forEach((event) => {
                    let event_id, weight, polarity, weight_persistence;
                    if (event.event_id) {
                      event_id = event.event_id;
                    } else {
                      event_id = undefined;
                    }
                    if (event.weight) {
                      weight = event.weight;
                    } else {
                      weight = 5;
                    }
                    if (event.polarity) {
                      polarity = event.polarity;
                    } else {
                      polarity = -1;
                    }
                    if (event.weight_persistence) {
                      weight_persistence = event.weight_persistence;
                    } else {
                      weight_persistence = 90;
                    }
                    if (event_id) {
                      payloadObjectTrigger['additional_params'][
                        event_id + '_weight'
                      ] = weight * polarity + ',' + weight_persistence;
                    }
                  });
                }
              }
            });
          }
          payloadObjectMetadata['triggers'].push(payloadObjectTrigger);
        } else if (this.currentAlert.alert_category === 'events') {
          payloadObjectMetadata['event_ids'] = this.currentAlert.events.map(
            (e) => e.id,
          );
          payloadObjectMetadata['threshold'] = this.currentAlert.threshold;
          payloadObjectMetadata['threshold_setting'] =
            this.currentAlert.threshold_setting;
          if (this.currentAlert.threshold === 0) {
            payloadObjectMetadata['threshold_setting'] = 'pass_through';
          }
          payloadObjectMetadata['additional_params'] = {};
        }
      } else {
        if (this.currentAlert.alert_category === 'scores') {
          payloadObjectMetadata['triggers'] = [];
          this.currentAlert.triggers.forEach((trigger) => {
            const payloadObjectTrigger = {};
            payloadObjectTrigger['score_type'] = trigger.score_type;
            if (trigger.score_type === 'sentiment') {
              payloadObjectTrigger['threshold'] = trigger.threshold / 100.0;
            } else {
              payloadObjectTrigger['threshold'] = trigger.threshold;
            }
            payloadObjectTrigger['threshold_setting'] =
              trigger.threshold_setting;
            payloadObjectTrigger['threshold_setting'] = trigger.isDeltaAlert
              ? 'z_score_unnormalized_threshold'
              : trigger.threshold_setting;
            payloadObjectTrigger['additional_params'] = {};
            payloadObjectTrigger['additional_params']['z_score_period'] =
              trigger.additional_params.z_score_period;
            payloadObjectTrigger['additional_params']['z_score_direction'] =
              trigger.additional_params.z_score_direction;
            payloadObjectTrigger['additional_params']['z_score_threshold'] =
              trigger.additional_params.z_score_threshold + '';
            payloadObjectTrigger['additional_params']['operator'] =
              trigger.threshold_setting;
            payloadObjectTrigger['additional_params']['plateau_period'] =
              trigger.additional_params.plateau_period;
            if (trigger.score_type === 'general_risk') {
              payloadObjectTrigger['additional_params'].with_black_swan =
                'true';
            } else if (trigger.score_type === 'esg_risk') {
              payloadObjectTrigger['additional_params'].with_black_swan =
                'true';
            } else if (trigger.score_type === 'immediate_risk') {
              payloadObjectTrigger['additional_params'].with_black_swan =
                'true';
            } else if (trigger.score_type === 'credit_risk') {
              payloadObjectTrigger['additional_params'].with_black_swan =
                'true';
              payloadObjectTrigger['additional_params'].risk_sensitivity = '0';
              payloadObjectTrigger['score_type'] = 'credit_watch';
            } else if (trigger.score_type === 'esg') {
              payloadObjectTrigger[
                'additional_params'
              ].disable_industry_materiality = 'true';
            } else if (trigger.score_type === 'bb') {
              payloadObjectTrigger['additional_params'].time_horizon = '1M';
            } else if (trigger.score_type) {
              this.auth.scorelabSettings.customScores.forEach((item) => {
                if (item.uid === trigger.score_type) {
                  if (item.sentiment_type === 'neg') {
                    payloadObjectTrigger['additional_params'][
                      'with_black_swan'
                    ] = 'true';
                  }
                  if (item.event_weights && item.event_weights.length > 0) {
                    item.event_weights.forEach((event) => {
                      let event_id, weight, polarity, weight_persistence;
                      if (event.event_id) {
                        event_id = event.event_id;
                      } else {
                        event_id = undefined;
                      }
                      if (event.weight) {
                        weight = event.weight;
                      } else {
                        weight = 5;
                      }
                      if (event.polarity) {
                        polarity = event.polarity;
                      } else {
                        polarity = -1;
                      }
                      if (event.weight_persistence) {
                        weight_persistence = event.weight_persistence;
                      } else {
                        weight_persistence = 90;
                      }
                      if (event_id) {
                        payloadObjectTrigger['additional_params'][
                          event_id + '_weight'
                        ] = weight * polarity + ',' + weight_persistence;
                      }
                    });
                  }
                }
              });
            }
            payloadObjectMetadata['triggers'].push(payloadObjectTrigger);
          });
        } else if (this.currentAlert.alert_category === 'events') {
          payloadObjectMetadata['event_ids'] = this.currentAlert.events.map(
            (e) => e.id,
          );
          payloadObjectMetadata['threshold'] = this.currentAlert.threshold;
          payloadObjectMetadata['threshold_setting'] =
            this.currentAlert.threshold_setting;
          if (this.currentAlert.threshold === 0) {
            payloadObjectMetadata['threshold_setting'] = 'pass_through';
          }
          payloadObjectMetadata['additional_params'] = {};
        }
      }
    }
  }

  private storeAlert(payloadObject, isNew, isActivated) {
    this.auth.addAlert(payloadObject).subscribe(
      (result) => {
        this.currentAlert.uid = result.uid;
        if (isNew) {
          this.alerts.push(this.currentAlert);
        }
        const alertsCopy = JSON.parse(JSON.stringify(this.alerts));
        if (!isNew) {
          alertsCopy.forEach((a, index) => {
            if (a.uid === this.currentAlert.uid) {
              alertsCopy[index] = JSON.parse(JSON.stringify(this.currentAlert));
            }
          });
        }
        this.alerts = [];
        this.alerts = alertsCopy;
        let alertMessage = 'saved';
        if (isActivated === true) {
          alertMessage = 'activated';
        } else if (isActivated === false) {
          alertMessage = 'deactivated';
        }
        this.snackBar.open('Alert ' + alertMessage + '.', 'OK', {
          duration: 5000,
        });
      },
      () => {
        this.snackBar.open(
          'Saving the Event Alert was not possible. Please reduce the number of Events.',
          'OK',
          { duration: 5000 },
        );
      },
    );
  }

  deleteAlert() {
    if (this.currentAlert.uid) {
      if (window.confirm('Delete ' + this.currentAlert.name + ' alert?')) {
        this.auth.removeAlert(this.currentAlert.uid).subscribe();
        this.alerts = this.alerts.filter((item) => {
          return item.uid !== this.currentAlert.uid;
        });
        this.onTabSelection(this.selectedTabIndex);
        this.snackBar.open('Alert deleted.', 'OK', { duration: 5000 });
      }
    }
  }

  addTrigger() {
    this.currentAlert.triggers.push({
      score_type: '',
      threshold: '',
      threshold_setting: '',
      isDeltaAlert: false,
      additional_params: {
        z_score_period: '7',
        z_score_direction: '',
        z_score_threshold: '',
        operator: '',
        plateau_period: '0',
      },
    });
  }

  delTrigger(index) {
    this.currentAlert.triggers.splice(index, 1);
  }

  iClose() {
    this.dialog.closeAll();
  }

  optSelect(option) {
    this.searchFormControl.setValue(option.entity.name);
    this.currentAlert.object_type = option.entity.type;
    this.currentAlert.object_id = option.entity.alpha_id;
    this.currentAlert.object_name = option.entity.name;
  }

  optSelect2() {
    this.currentAlert.object_type = '';
    this.currentAlert.object_id = '';
    this.currentAlert.object_name = '';
    this.currentAlert.triggers = [
      {
        score_type: '',
        threshold: '',
        threshold_setting: '',
        isDeltaAlert: false,
        additional_params: {
          z_score_period: '7',
          z_score_direction: '',
          z_score_threshold: '',
          operator: '',
          plateau_period: '0',
        },
      },
    ];
    this.searchFormControl.setValue('');
  }

  optSelect3(event, type) {
    let name = '';
    if (type === 'portfolio') {
      name = this.folio.filter((element) => {
        return element.uid === event.value;
      })[0].name;
      this.currentAlert.object_type = 'portfolio';
    }
    this.currentAlert.object_name = name;
  }

  updateForms() {
    this.emailList = this.currentAlert.additional_recipients;

    this.searchFormControl = new UntypedFormControl();

    let name = '';
    this.searchFormControl.setValue('');

    if (this.currentAlert.object_type && this.currentAlert.object_id) {
      if (this.currentAlert.alert_type === 'entity') {
        this.yukkApi
          .isentiment(
            {
              type: this.currentAlert.object_type,
              id: this.currentAlert.object_id,
              fromAlertsSetup: true,
            },
            false,
          )
          .subscribe((result) => {
            this.searchFormControl.setValue(result.entity.name);
            this.currentAlert.object_name = result.entity.name;
          });
      } else if (this.currentAlert.alert_type === 'portfolio') {
        name = this.folio
          ? this.folio.filter((element) => {
              return element.uid === this.currentAlert.object_id;
            })[0].name
          : '';
        this.currentAlert.object_name = name;
      }
    }

    this.searchFormControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => {
        if (value && value.length > 1) {
          this.loading = true;
          this.items$ = this.yukkApi.search(value, false).pipe(
            mergeMap((result) => {
              if (result && result.hits.length === 0) {
                const words = value.split(' ').filter((item) => item !== '');
                return this.yukkApi
                  .search(words[0] ? words[0] : ' ', false)
                  .pipe(
                    map((result2) => {
                      this.loading = false;
                      return result2;
                    }),
                  );
              } else {
                this.loading = false;
                return of(result);
              }
            }),
          );
        }
      });
  }

  onChannelChange() {
    if (this.currentAlert.channel === 'digest') {
      /* empty */
    } else if (this.currentAlert.channel === 'explainable_digest') {
      this.currentAlert.triggers = [
        {
          score_type: '',
          threshold: '',
          threshold_setting: '',
          isDeltaAlert: false,
          additional_params: {
            z_score_period: '7',
            z_score_direction: '',
            z_score_threshold: '',
            operator: '',
            plateau_period: '0',
          },
        },
      ];
    }
  }

  onScoreChange(event, index) {
    this.currentAlert.triggers[index].threshold_setting = 'cross_above';
    if (event.value === 'sentiment') {
      this.currentAlert.triggers[index].threshold = 50;
    } else if (event.value === 'general_risk') {
      this.currentAlert.triggers[index].threshold = 5;
    } else if (event.value === 'esg_risk') {
      this.currentAlert.triggers[index].threshold = 4;
    } else if (event.value === 'immediate_risk') {
      this.currentAlert.triggers[index].threshold = 3.5;
    } else if (event.value === 'credit_risk') {
      this.currentAlert.triggers[index].threshold = 5.5;
    } else if (event.value === 'esg') {
      this.currentAlert.triggers[index].threshold = 5;
    } else if (event.value === 'eb_sim') {
      this.currentAlert.triggers[index].threshold = 5;
    } else if (event.value === 'eb_sim_risk') {
      this.currentAlert.triggers[index].threshold = 5;
    } else if (event.value === 'bb') {
      this.currentAlert.triggers[index].threshold = 5;
    }
    this.currentAlert.triggers[index].isDeltaAlert = false;
    this.currentAlert.triggers[index].additional_params.z_score_period = '7';
    this.currentAlert.triggers[index].additional_params.z_score_direction = '';
    this.currentAlert.triggers[index].additional_params.z_score_period = '';
    this.currentAlert.triggers[index].additional_params.z_score_period = '';
    this.currentAlert.triggers[index].additional_params.z_score_period = '0';
  }

  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(
      (item) => item.name.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  displayFn(element) {
    return element && element.name ? element.name : '';
  }

  /**
   *
   */
  iMatch(entity) {
    if (entity.matched_form) {
      return (
        entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0)
      );
    }
  }

  /**
   * format big numbers >999 into K, M, ...
   */
  numberFormat(num) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
  }

  /**
   * formatting label to be displayed for different events impacts values
   */
  sliderLabel(value: number): string {
    if (value === 0) {
      return 'PASS';
    } else if (value === 1) {
      return 'Very Low';
    } else if (value === 2) {
      return 'Low';
    } else if (value === 3) {
      return 'Medium';
    } else if (value === 4) {
      return 'High';
    } else if (value === 5) {
      return 'Very High';
    }

    return `${value}`;
  }

  onEventsScoreChange(event, clear = false) {
    const changeScore = () => {
      this.yukkApi.scoreWeights({}, event.value).subscribe((response) => {
        const res = JSON.parse(JSON.stringify(response));
        if (event.value === 'sentiment') {
          this.strictFiltering = false;
          this.scoreEvents = JSON.parse(
            JSON.stringify(this.events.map((el) => el.id)),
          );
        } else {
          this.strictFiltering = true;
          this.scoreEvents = res;
        }
        this.scoreCategories = [
          ...new Set(
            this.events
              .filter((el) => this.scoreEvents.includes(el.id))
              .map((el) => el.categoryId),
          ),
        ];
        if (clear) {
          this.selectedEvents = [];
          this.eventsControl.setValue(this.selectedEvents);
          this.events.forEach((el) => {
            el.selected = false;
          });
          this.eventCategories.forEach((el) => {
            el.selected = false;
          });
          this.selectedEventsCopy = JSON.parse(
            JSON.stringify(this.selectedEvents),
          );
          this.eventsControl.setValue(this.selectedEvents);
        }
      });
    };
    changeScore();
  }

  onAutocompleteOpen() {
    this.closeAfterSelection = false;
    this.selectedEventsCopy = JSON.parse(JSON.stringify(this.selectedEvents));
  }

  onAutocompleteClose() {
    if (!this.closeAfterSelection) {
      this.updateSelectedEventsWithCopy();
    }
    this.closeAfterSelection = false;
  }

  clearEvents() {
    this.onEventsScoreChange({ value: this.currentAlert.score_type }, true);
  }

  selectEvents() {
    this.selectedEventsCopy = JSON.parse(JSON.stringify(this.selectedEvents));
    this.eventsControl.setValue(this.selectedEvents);
    this.closeAfterSelection = true;
    this.closePanels();
  }

  closePanels() {
    this.autocompleteTriggers.forEach((trigger) => {
      if (trigger.panelOpen) {
        trigger.closePanel();
      }
    });
  }

  triggerAlert() {
    if (this.currentAlert.uid) {
      this.auth.triggerAlert(this.currentAlert.uid).subscribe(
        (res) => {
          this.snackBar.open(
            'Alert of id ' +
              this.currentAlert.uid +
              ' was triggered, job of id ' +
              res['job_uid'] +
              ' was created.',
            'OK',
            { duration: 5000 },
          );
        },
        () => {
          this.snackBar.open('Something went wrong.', 'OK', { duration: 5000 });
        },
      );
    }
  }

  getAlertHistory() {
    if (this.currentAlert.uid) {
      this.auth.getAlertHistory(this.currentAlert.uid).subscribe(
        () => {},
        () => {
          this.snackBar.open('Something went wrong.', 'OK', { duration: 5000 });
        },
      );
    }
  }

  showEventsFilterLabel() {
    if (this.currentAlert.score_type && this.scoreEventsTypes) {
      return this.scoreEventsTypes
        .filter((el) => el.value === this.currentAlert.score_type)
        .map((el) => el.label)[0];
    } else {
      return '';
    }
  }

  markFieldAsSeen(isVisible: boolean): void {
    if (isVisible) {
      this.fieldsSeen = true;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const emails = this.extractEmails(value.trim());
      if (emails) {
        if (emails.length === 1 && emails[0] === this.user.email) {
          alert('Provided email is already added to the recipients list.');
        } else if (
          emails.length === 1 &&
          emails[0].split('@')[1] !== this.user.email.split('@')[1]
        ) {
          alert(
            'Provided email is outside of ' +
              this.user.email.split('@')[1] +
              ' domain.',
          );
        } else {
          emails.forEach((email) => {
            if (email !== this.user.email && !this.emailList.includes(email)) {
              this.emailList.push(email);
            }
          });
        }
      } else {
        alert('Error: please enter valid email.');
      }
    }
    this.currentAlert.additional_recipients = this.emailList;
    if (input) {
      input.value = '';
    }
  }

  remove(email): void {
    const index = this.emailList.indexOf(email);
    if (index >= 0) {
      this.emailList.splice(index, 1);
    }
  }

  extractEmails(text) {
    return text.match(
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi,
    );
  }
}
