<div #newsfeedContainer class="news-feed">
  <div class="news-bar" *ngIf="routing.isNewsaudit()">
    <div class="cont">
      <div class="border-scroll"></div>
      <button mat-button>
        <span>Articles</span>
      </button>
    </div>
    <div class="audit-info">
      <div class="audit-info-events">
        <button mat-button>
          <span
            *ngIf="
              params.newstype &&
              params.newstype !== 'Flagged' &&
              params.newstype !== 'Dismissed'
            "
            >Events</span
          >
          <span
            *ngIf="
              params.newstype &&
              (params.newstype === 'Flagged' || params.newstype === 'Dismissed')
            "
            >Status</span
          >
        </button>
      </div>
      <div class="audit-info-entities">
        <button mat-button>
          <span
            [class.hidden]="params.tag"
            *ngIf="
              params.newstype &&
              params.newstype !== 'Flagged' &&
              params.newstype !== 'Dismissed'
            "
            >Participants</span
          >
          <span
            *ngIf="
              params.newstype &&
              (params.newstype === 'Flagged' || params.newstype === 'Dismissed')
            "
            >Subjects</span
          >
        </button>
      </div>
    </div>
    <div class="audit-actions">
      <div mat-icon-button class="export-audits"></div>
      <div mat-icon-button class="export-audits"></div>
      <div mat-icon-button class="export-audits"></div>
    </div>
  </div>

  <div
    class="news-bar"
    *ngIf="
      (auth.featureFlags.showKeywordSearch || routing.isDev()) &&
      (params.newstype === 'Relevant' ||
        params.newstype === 'Chronological' ||
        params.newstype === 'Stories' ||
        newstype === 'Relevant' ||
        newstype === 'Chronological' ||
        newstype === 'Stories')
    "
  >
    <div class="news-bar-search">
      <mat-icon>search</mat-icon>
      <input
        matInput
        autocomplete="off"
        placeholder="Search for keywords..."
        [formControl]="keywordSearch"
        matAutocompletePosition="below"
      />
    </div>
  </div>

  <div
    class="newscroll"
    [class.trending]="routing.isHot() || routing.isStoryReader()"
    #newscroll
    infiniteScroll
    [infiniteScrollThrottle]="10"
    [infiniteScrollDistance]="4"
    [scrollWindow]="false"
    (scrolled)="inScrolled()"
    (scroll)="inScrolling($event)"
  >
    <div
      class="loading"
      *ngIf="loading && (!params || (params && params.id !== 'default'))"
    >
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="nodata" *ngIf="nodata && params && params.id !== 'default'">
      SOMETHING WENT WRONG, PLEASE TRY TO REFRESH THE PAGE
    </div>
    <div
      class="nodata"
      *ngIf="
        !loading &&
        !nodata &&
        params &&
        params.id !== 'default' &&
        newsfeed?.length === 0
      "
    >
      NO ARTICLES FOUND
    </div>
    <div
      class="nodata"
      *ngIf="
        params &&
        params.id === 'default' &&
        auth.querys &&
        auth.querys.length > 0
      "
    >
      PLEASE CHOOSE A QUERY OR COLLECTION TO DISPLAY THE NEWSFEED
    </div>
    <div
      class="nodata"
      *ngIf="
        params &&
        params.id === 'default' &&
        auth.querys &&
        auth.querys.length === 0
      "
    >
      In a collection, you can add multiple different types and combinations of
      entities such as topics, events, companies and people through a Boolean
      query. A combination of these queries can then be saved to a “Collection”.
      Click "+" to create a new collection.
    </div>

    <!--<div class="nodata button-middle" *ngIf="!loading && params && params.type && (params.id !== 'default') && !nodata && (newsfeed?.length==0) && (newstype === 'Chronological')">
      <button mat-stroked-button>Find more articles <mat-icon>search</mat-icon></button>
    </div>-->

    <div
      class="nodata button-end"
      *ngIf="
        !loading &&
        !nodata &&
        params &&
        params.type &&
        params.id !== 'default' &&
        newsfeed?.length < 5 &&
        newstype === 'Chronological'
      "
    >
      <button mat-stroked-button (click)="buildSearchQuery()">
        Find more articles <mat-icon>search</mat-icon>
      </button>
    </div>

    <mat-list
      *ngIf="
        !loading &&
        !nodata &&
        params &&
        params.id !== 'default' &&
        newsfeed.length > 0 &&
        params.newstype !== 'Stories' &&
        newstype !== 'Stories' &&
        params.newstype !== 'Relevant' &&
        newstype !== 'Relevant'
      "
    >
      <!--<mat-list-item *ngIf="(newstype === 'Relevant')">
        <div class="frame warning relevant-header" mat-line>
          <span>Most Relevant Articles</span>
        </div>
      </mat-list-item>-->
      <mat-list-item>
        <div
          class="frame warning"
          mat-line
          *ngIf="!params.newsday && params.scoreevent && checkTimeframe()"
        >
          <span>Last 100 days<!-- | Categories filter disabled--></span>
        </div>
        <div
          class="frame warning"
          mat-line
          *ngIf="!params.newsday && params.flagevent && checkTimeframe()"
        >
          <span>Last 465 days<!-- | Categories filter disabled--></span>
        </div>
        <!--<div class="frame warning" mat-line *ngIf="(params.scoreevent || params.flagevent) && !checkTimeframe()">
          <span>Categories filter disabled</span>
        </div>-->
      </mat-list-item>
      <mat-list-item *ngFor="let news of newsfeed; index as index">
        <div
          class="frame"
          mat-line
          *ngIf="news.time && !news.hidden && !(newstype === 'Relevant')"
        >
          <mat-icon class="flip">update</mat-icon>
          <span>{{ news.time }}</span>
          <span class="time-zone-info">(Time Zone: GMT)</span>
        </div>
        <div
          mat-line
          *ngIf="!news.hidden"
          class="bloksent box-map"
          [class.recommended]="news.recommendation"
          [class.resolved]="
            dimmed &&
            params.newstype === 'Flagged' &&
            news.resolved &&
            news.resolved === 'Resolved'
          "
          [class.seen]="
            fadeout &&
            auth.seenArticles &&
            auth.seenArticles.includes(news.id) &&
            params.newstype !== 'Flagged' &&
            params.newstype !== 'Dismissed'
          "
          [class.inew]="news.inew"
          [matTooltipDisabled]="
            routing.isMobile() || routing.isIframe() || fullscreen
          "
          [matTooltip]="getTeaser(news)"
          matTooltipPosition="right"
          matTooltipShowDelay="200"
          matTooltipClass="newstooltip"
          (mouseenter)="onMouseEnterRow(news)"
          (mouseleave)="onMouseLeaveRow(news)"
        >
          <div class="news-container">
            <div class="cont" (click)="goNews(news, 'feednews')">
              <div class="border" *ngIf="news.sentiment_proportions">
                <div
                  *ngIf="auth.showArticleSentiment"
                  class="bar pos"
                  [style.height]="news.sentiment_proportions.pos | percent"
                ></div>
                <div
                  *ngIf="auth.showArticleSentiment"
                  class="bar neu"
                  [style.height]="news.sentiment_proportions.neu | percent"
                ></div>
                <div
                  *ngIf="auth.showArticleSentiment"
                  class="bar neg"
                  [style.height]="news.sentiment_proportions.neg | percent"
                ></div>
              </div>
              <div class="tito" [class.blur]="news.reduce_visibility">
                <div mat-line [innerHTML]="news.titleFormatted"></div>
              </div>
              <div class="subo" mat-line>
                <div class="blok">
                  <span
                    >{{
                      news.publish_time | date: 'dd.MM.yyyy HH:mm' : 'GMT'
                    }}
                    GMT</span
                  >
                </div>
                <div class="blok">
                  <span> | </span>
                </div>
                <div class="blok blok2" *ngIf="news.provider">
                  <span>{{ news.provider }}</span>
                </div>
                <ng-container
                  *ngIf="
                    params.newstype === 'Events' &&
                    news.events &&
                    news.events.length > 0 &&
                    ((news.events[0].temporality &&
                      news.events[0].temporality !== 'NONE' &&
                      news.events[0].temporality !== 'UNKNOWN') ||
                      (news.events[0].factuality &&
                        news.events[0].factuality !== 'NONE' &&
                        news.events[0].factuality !== 'UNKNOWN'))
                  "
                >
                  <div class="blok">
                    <span> | </span>
                  </div>
                  <div class="blok blok2">
                    <span
                      *ngIf="
                        news.events[0].temporality !== 'NONE' &&
                        news.events[0].temporality !== 'UNKNOWN'
                      "
                      >{{ news.events[0].temporality }}</span
                    >
                    <span
                      *ngIf="
                        news.events[0].factuality !== 'NONE' &&
                        news.events[0].factuality !== 'UNKNOWN'
                      "
                      >,&nbsp;</span
                    >
                    <span
                      *ngIf="
                        ['FACT', 'COUNTERFACT'].includes(
                          news.events[0].factuality
                        )
                      "
                      >FACT</span
                    >
                    <span
                      *ngIf="
                        ['PROBABLE', 'COUNTERPROBABLE'].includes(
                          news.events[0].factuality
                        )
                      "
                      >PROBABLE</span
                    >
                    <span
                      *ngIf="
                        ['POSSIBLE', 'COUNTERPOSSIBLE'].includes(
                          news.events[0].factuality
                        )
                      "
                      >POSSIBLE</span
                    >
                  </div>
                </ng-container>
                <div
                  class="blok icon"
                  *ngIf="
                    news.document_categorization &&
                    news.document_categorization.is_licensed_content
                  "
                  matTooltip="Licensed content with fulltext"
                >
                  <img *ngIf="routing.theme !== 'light'" src="assets/logo/yukka_mobile.svg" />
                  <img *ngIf="routing.theme === 'light'" src="assets/logo/yukka_mobile_light.svg" />
                </div>
              </div>
            </div>
            <div
              class="audit-info"
              *ngIf="
                routing.isNewsaudit() &&
                !(
                  params.newstype === 'Flagged' ||
                  params.newstype === 'Dismissed'
                )
              "
            >
              <div class="audit-info-events">
                <button
                  class="no-action"
                  mat-button
                  [matMenuTriggerFor]="menuAuditEvents"
                  #menuTriggerEvents="matMenuTrigger"
                  (mouseenter)="onMouseEnter(menuTriggerEvents, 'button')"
                  (mouseleave)="onMouseLeave(menuTriggerEvents, 'button')"
                >
                  <span mat-line
                    >{{ news.auditEvents ? news.auditEvents[0] : ''
                    }}{{
                      news.auditEvents && news.auditEvents.length > 1
                        ? ' +' + (news.auditEvents.length - 1)
                        : ''
                    }}</span
                  >
                </button>
                <mat-menu #menuAuditEvents="matMenu" overlapTrigger="true">
                  <div
                    (mouseenter)="onMouseEnter(menuTriggerEvents, 'menu')"
                    (mouseleave)="onMouseLeave(menuTriggerEvents, 'menu')"
                  >
                    <button
                      class="no-action"
                      mat-menu-item
                      *ngFor="let event of news.auditEvents"
                    >
                      {{ event }}
                    </button>
                  </div>
                </mat-menu>
              </div>
              <div class="audit-info-entities">
                <button
                  class="no-action"
                  mat-button
                  [matMenuTriggerFor]="menuAuditEntities"
                  #menuTriggerEntities="matMenuTrigger"
                  (mouseenter)="onMouseEnter(menuTriggerEntities, 'button')"
                  (mouseleave)="onMouseLeave(menuTriggerEntities, 'button')"
                >
                  <span [class.hidden]="params.tag" mat-line
                    >{{ news.auditParticipants ? news.auditParticipants[0] : ''
                    }}{{
                      news.auditParticipants &&
                      news.auditParticipants.length > 1
                        ? ' +' + (news.auditParticipants.length - 1)
                        : ''
                    }}</span
                  >
                </button>
                <mat-menu #menuAuditEntities="matMenu" overlapTrigger="true">
                  <div
                    (mouseenter)="onMouseEnter(menuTriggerEntities, 'menu')"
                    (mouseleave)="onMouseLeave(menuTriggerEntities, 'menu')"
                  >
                    <button
                      class="no-action"
                      mat-menu-item
                      *ngFor="let entity of news.auditParticipants"
                    >
                      {{ entity }}
                    </button>
                  </div>
                </mat-menu>
              </div>
            </div>
            <div
              class="audit-info"
              *ngIf="
                routing.isNewsaudit() &&
                (params.newstype === 'Flagged' ||
                  params.newstype === 'Dismissed')
              "
            >
              <div
                class="audit-info-events"
                *ngIf="params.newstype === 'Flagged'"
              >
                <button class="no-action" mat-button>
                  <span
                    mat-line
                    matTooltipPosition="above"
                    matTooltip="{{ news.resolved + ' (' }}{{
                      (news.timestamp | date: 'dd.MM.yyyy HH:mm' : 'GMT') + ')'
                    }}"
                    >{{ news.resolved + ' ('
                    }}{{
                      (news.timestamp | date: 'dd.MM.yyyy HH:mm' : 'GMT') + ')'
                    }}</span
                  >
                </button>
              </div>
              <div
                class="audit-info-events"
                *ngIf="params.newstype === 'Dismissed'"
              >
                <button class="no-action" mat-button>
                  <span
                    mat-line
                    matTooltipPosition="above"
                    matTooltip="{{
                      'Dismissed (' +
                        (news.timestamp | date: 'dd.MM.yyyy HH:mm' : 'GMT') +
                        ')'
                    }}"
                    >{{
                      'Dismissed (' +
                        (news.timestamp | date: 'dd.MM.yyyy HH:mm' : 'GMT') +
                        ')'
                    }}</span
                  >
                </button>
              </div>
              <div class="audit-info-entities">
                <button
                  class="no-action"
                  mat-button
                  [matMenuTriggerFor]="menuAuditSubjects"
                  #menuTriggerSubjects="matMenuTrigger"
                  (mouseenter)="onMouseEnter(menuTriggerSubjects, 'button')"
                  (mouseleave)="onMouseLeave(menuTriggerSubjects, 'button')"
                >
                  <span mat-line>{{
                    news.subjects ? news.subjects[0].subject : ''
                  }}</span>
                </button>
                <mat-menu #menuAuditSubjects="matMenu" overlapTrigger="true">
                  <div
                    (mouseenter)="onMouseEnter(menuTriggerSubjects, 'menu')"
                    (mouseleave)="onMouseLeave(menuTriggerSubjects, 'menu')"
                  >
                    <button
                      class="no-action"
                      mat-menu-item
                      *ngFor="let subject of news.subjects"
                    >
                      {{ subject.subject }}
                    </button>
                  </div>
                </mat-menu>
              </div>
            </div>
            <div
              mat-line
              class="audit-actions"
              *ngIf="
                routing.isNewsaudit() &&
                !(
                  params.newstype === 'Flagged' ||
                  params.newstype === 'Dismissed'
                )
              "
              [class.transparent]="!news.visible"
            >
              <div
                mat-icon-button
                class="audit"
                matTooltip="Flag"
                matTooltipPosition="below"
                (click)="goNews(news, 'audit')"
                *ngIf="
                  auth.featureFlags.flaggingEnabled &&
                  params.newstype !== 'Flagged' &&
                  params.newstype !== 'Dismissed'
                "
              >
                <mat-icon>flag</mat-icon>
              </div>
              <div
                mat-icon-button
                class="dismiss"
                matTooltip="Dismiss"
                matTooltipPosition="below"
                (click)="goNews(news, 'dismiss')"
                *ngIf="
                  auth.featureFlags.dismissingEnabled &&
                  params.newstype !== 'Flagged' &&
                  params.newstype !== 'Dismissed'
                "
              >
                <mat-icon>remove_circle</mat-icon>
              </div>
              <div
                mat-icon-button
                class="bookmark"
                matTooltip="{{
                  !iBookmark(news.id) ? 'Bookmark' : 'Unbookmark'
                }}"
                matTooltipPosition="below"
                (click)="addStar(news.id)"
                *ngIf="
                  auth.featureFlags.bookmarkingEnabled &&
                  params.newstype !== 'Flagged' &&
                  params.newstype !== 'Dismissed'
                "
              >
                <mat-icon [class.starred]="iBookmark(news.id)"
                  >bookmark</mat-icon
                >
              </div>
            </div>
            <div
              mat-line
              class="audit-actions"
              *ngIf="
                routing.isNewsaudit() &&
                (params.newstype === 'Flagged' ||
                  params.newstype === 'Dismissed')
              "
            >
              <div
                mat-icon-button
                class="audit"
                matTooltip="Resolve case"
                matTooltipPosition="below"
                (click)="goNews(news, 'resolve')"
                *ngIf="
                  params.newstype === 'Flagged' && news.resolved === 'Opened'
                "
              >
                <mat-icon>assignment_turned_in</mat-icon>
              </div>
              <div
                mat-icon-button
                class="audit"
                matTooltip="Check resolved case"
                matTooltipPosition="below"
                (click)="goNews(news, 'check')"
                *ngIf="
                  params.newstype === 'Flagged' && news.resolved === 'Resolved'
                "
              >
                <mat-icon>assignment_returned</mat-icon>
              </div>
              <div
                mat-icon-button
                class="dismiss"
                matTooltip="Dismiss"
                matTooltipPosition="below"
                (click)="goNews(news, 'dismiss')"
                *ngIf="
                  params.newstype !== 'Flagged' &&
                  params.newstype !== 'Dismissed'
                "
              >
                <mat-icon>remove_circle</mat-icon>
              </div>
              <div
                mat-icon-button
                class="dismiss"
                matTooltip="Check dismissed case"
                matTooltipPosition="below"
                (click)="goNews(news, 'check')"
                *ngIf="params.newstype === 'Dismissed'"
              >
                <mat-icon>assignment_returned</mat-icon>
              </div>
            </div>
            <div
              mat-line
              class="audit-actions"
              *ngIf="
                routing.isNewsletter() &&
                !(newsfeedData && newsfeedData.fromAssistedBookmarking)
              "
            >
              <div
                mat-icon-button
                class="dismiss"
                matTooltip="Check/uncheck article to be dismissed from the newsletter"
                matTooltipPosition="below"
                (click)="$event.stopPropagation(); dismissNews(news)"
              >
                <mat-checkbox></mat-checkbox>
              </div>
            </div>
            <!--<div mat-line class="audit-actions" *ngIf="routing.isNewsletter() && (newsfeedData && newsfeedData.fromAssistedBookmarking)" [class.transparent]="!news.visible">
              <div mat-icon-button class="bookmark" matTooltip="{{!iBookmark(news.id) ? 'Bookmark' : 'Unbookmark'}}" matTooltipPosition="below" (click)="addStar(news.id)" *ngIf="auth.featureFlags.bookmarkingEnabled && (params.newstype !== 'Flagged') && (params.newstype !== 'Dismissed')">
                <mat-icon [class.starred]="iBookmark(news.id)">bookmark</mat-icon>
              </div>
            </div>-->
          </div>
          <div class="scroload" *ngIf="loadnews === news.id">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </mat-list-item>
    </mat-list>

    <mat-list
      *ngIf="
        !loading &&
        !nodata &&
        params &&
        params.id !== 'default' &&
        newsfeed.length > 0 &&
        (params.newstype === 'Stories' ||
          newstype === 'Stories' ||
          params.newstype === 'Relevant' ||
          newstype === 'Relevant')
      "
    >
      <mat-list-item>
        <div
          class="frame warning"
          mat-line
          *ngIf="!params.newsday && params.scoreevent && checkTimeframe()"
        >
          <!--<mat-icon class="flip">warning</mat-icon>-->
          <span>Last 100 days<!-- | Categories filter disabled--></span>
        </div>
        <div
          class="frame warning"
          mat-line
          *ngIf="!params.newsday && params.flagevent && checkTimeframe()"
        >
          <!--<mat-icon class="flip">warning</mat-icon>-->
          <span>Last 465 days<!-- | Categories filter disabled--></span>
        </div>
        <div
          class="frame warning"
          mat-line
          *ngIf="
            !params.newsday &&
            (params.scoreevent || params.flagevent) &&
            !checkTimeframe()
          "
        >
          <!--<mat-icon class="flip">warning</mat-icon>-->
          <!--<span>Categories filter disabled</span>-->
        </div>
      </mat-list-item>
      <mat-list-item
        class="story-box"
        [class.scanner-stories]="routing.isScanner()"
        [class.trending-stories]="routing.isHot()"
        *ngFor="let story of newsfeed; index as index"
      >
        <div
          *ngIf="story && story.size"
          class="frame stories top"
          mat-line
          [matTooltip]="story?.story"
          [class.blur]="story?.article?.reduce_visibility"
          (click)="
            goNews({ story: story, news: story.article }, 'feedstories2')
          "
        >
          <span
            class="story-title"
            [innerHTML]="story.article.titleFormatted"
          ></span>
        </div>
        <div
          mat-line
          *ngIf="story && story.article && !story.article.hidden"
          class="bloksent bloksent-story box-map story-teaser-container"
          [class.inew]="story.article.inew"
          [matTooltipDisabled]="
            routing.isMobile() || routing.isIframe() || fullscreen
          "
          [matTooltip]="getTeaser(story.article)"
          matTooltipPosition="right"
          matTooltipShowDelay="200"
          matTooltipClass="newstooltip"
        >
          <div class="news-container">
            <div
              class="cont"
              (click)="
                goNews({ story: story, news: story.article }, 'feedstories2')
              "
            >
              <div
                class="tito story-teaser"
                [class.blur]="story.article.reduce_visibility"
              >
                <div
                  class="story-teaser-inner"
                  [innerHTML]="getTeaser(story.article)"
                ></div>
              </div>
              <div class="subo story-teaser" mat-line>
                <div class="blok">
                  <span
                    >{{
                      story.article.publish_time
                        | date: 'dd.MM.yyyy HH:mm' : 'GMT'
                    }}
                    GMT</span
                  >
                </div>
                <div class="blok">
                  <span> | </span>
                </div>
                <div class="blok blok2" *ngIf="story.article.provider">
                  <span>{{ story.article.provider }}</span>
                </div>
                <div
                  class="blok icon"
                  *ngIf="
                    story.article.document_categorization &&
                    story.article.document_categorization.is_licensed_content
                  "
                  matTooltip="Licensed content with fulltext"
                >
                  <img *ngIf="routing.theme !== 'light'" src="assets/logo/yukka_mobile.svg" />
                  <img *ngIf="routing.theme === 'light'" src="assets/logo/yukka_mobile_light.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div mat-line *ngIf="!morenews[index] && !story.news[0].hidden" class="bloksent bloksent-story box-map"  [class.seen]="fadeout && auth.seenArticles && auth.seenArticles.includes(story.news[0].id)" [class.inew]="story.news[0].inew" [matTooltipDisabled]="routing.isMobile()||routing.isIframe()||fullscreen" [matTooltip]="getTeaser(story.news[0])" matTooltipPosition="right" matTooltipShowDelay="200" matTooltipClass="newstooltip">
          <div class="news-container">
            <div class="cont" (click)="goNews({story: story, news: story.news[0]}, 'feedstories')">
              <div class="tito" [class.blur]="story.news[0].reduce_visibility">
                <div class="bullet">&#8226;</div>
                <div mat-line [innerHTML]="getTitle(story.news[0])"></div>
              </div>
              <div class="subo" mat-line>
                <div class="blok">
                  <span>{{story.news[0].publish_time|date:'dd.MM.yyyy HH:mm':'GMT'}}</span>
                </div>
                <div class="blok">
                  <span> | </span>
                </div>
                <div class="blok blok2" *ngIf="story.news[0].provider">
                  <span>{{story.news[0].provider}}</span>
                </div>
                <div class="blok icon" *ngIf="story.news[0].document_categorization && story.news[0].document_categorization.is_licensed_content" matTooltip="Licensed content with fulltext">
                  <img src="assets/logo/prod_mobile.svg">
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div
          mat-line
          *ngIf="story && story.size && morenews[index]"
          class="news-group-container"
          [style.max-height.px]="storyContentHeight"
        >
          <ng-container *ngFor="let news of story.news">
            <div
              mat-line
              *ngIf="!news.hidden"
              class="bloksent bloksent-story box-map"
              [class.seen]="
                fadeout &&
                auth.seenArticles &&
                auth.seenArticles.includes(news.id)
              "
              [class.inew]="news.inew"
              [matTooltipDisabled]="
                routing.isMobile() || routing.isIframe() || fullscreen
              "
              [matTooltip]="getTeaser(news)"
              matTooltipPosition="right"
              matTooltipShowDelay="200"
              matTooltipClass="newstooltip"
            >
              <div class="news-container">
                <div
                  class="cont"
                  (click)="goNews({ story: story, news: news }, 'feedstories')"
                >
                  <div class="tito" [class.blur]="news.reduce_visibility">
                    <div class="bullet">&#8226;</div>
                    <div mat-line [innerHTML]="news.titleFormatted"></div>
                  </div>
                  <div class="subo" mat-line>
                    <div class="blok">
                      <span
                        >{{
                          news.publish_time | date: 'dd.MM.yyyy HH:mm' : 'GMT'
                        }}
                        GMT</span
                      >
                    </div>
                    <div class="blok">
                      <span> | </span>
                    </div>
                    <div class="blok blok2" *ngIf="news.provider">
                      <span>{{ news.provider }}</span>
                    </div>
                    <div
                      class="blok icon"
                      *ngIf="
                        news.document_categorization &&
                        news.document_categorization.is_licensed_content
                      "
                      matTooltip="Licensed content with fulltext"
                    >
                      <img *ngIf="routing.theme !== 'light'" src="assets/logo/yukka_mobile.svg" />
                      <img *ngIf="routing.theme === 'light'" src="assets/logo/yukka_mobile_light.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="story && story.size" class="frame stories bottom" mat-line>
          <div class="more-info" (click)="getMoreNews(story, index)">
            <mat-icon *ngIf="!morenews[index]">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="morenews[index]">arrow_drop_up</mat-icon>
          </div>
          <div class="story-info" (click)="getMoreNews(story, index)">
            <span>{{ story.size + ' Articles' }}</span>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

  <!--<div class="floating-actions float-left show" *ngIf="routing.isNewsletter() && (params.newstype === 'Bookmarked') && !(newsfeedData && newsfeedData.fromAssistedBookmarking)">
    <button mat-button (click)="openRecommendationsSetup()" matTooltip="Try the Assisted Bookmarking Tool to find more relevant articles">Assisted Bookmarking Tool</button>
  </div>-->

  <div
    class="floating-actions"
    *ngIf="routing.isNewsletter() && selectedArticles.length !== 0"
  >
    <mat-icon
      (click)="dismissManyNews()"
      matTooltip="Dismiss selected articles from the newsletter"
      >clear</mat-icon
    >
  </div>

  <div
    class="floating-actions float-more2 show"
    *ngIf="
      (auth.authToken().roles?.includes('ADMIN') ||
        auth.authToken().groups?.includes('ADMIN') ||
        auth.featureFlags.importExportBookmarks) &&
      params.newstype === 'Bookmarked' &&
      newsfeed &&
      newsfeed.length !== 0
    "
  >
    <mat-icon
      matTooltip="Import bookmarks"
      svgIcon="tray-arrow-up"
      (click)="fileInputBookmarks.click()"
    ></mat-icon>
    <input
      #fileInputBookmarks
      class="inptfile"
      type="file"
      accept=".json"
      value=""
      (click)="fileInputBookmarks.value = null"
      (change)="importBookmarkedArticles($event)"
      multiple
    />
  </div>

  <div
    class="floating-actions show"
    *ngIf="
      (auth.authToken().roles?.includes('ADMIN') ||
        auth.authToken().groups?.includes('ADMIN') ||
        auth.featureFlags.importExportBookmarks) &&
      params.newstype === 'Bookmarked' &&
      (!newsfeed || newsfeed.length === 0)
    "
  >
    <mat-icon
      matTooltip="Import bookmarks"
      svgIcon="tray-arrow-up"
      (click)="fileInputBookmarks2.click()"
    ></mat-icon>
    <input
      #fileInputBookmarks2
      class="inptfile"
      type="file"
      accept=".json"
      value=""
      (click)="fileInputBookmarks2.value = null"
      (change)="importBookmarkedArticles($event)"
      multiple
    />
  </div>

  <div
    class="floating-actions float-more show"
    *ngIf="
      (auth.authToken().roles?.includes('ADMIN') ||
        auth.authToken().groups?.includes('ADMIN') ||
        auth.featureFlags.importExportBookmarks) &&
      params.newstype === 'Bookmarked' &&
      newsfeed &&
      newsfeed.length !== 0
    "
  >
    <mat-icon
      (click)="exportBookmarkedArticles(newsfeed)"
      matTooltip="Export bookmarks"
      svgIcon="tray-arrow-down"
    ></mat-icon>
  </div>

  <!--<div class="floating-actions" [class.show]="!showRecommendations" *ngIf="!routing.isNewsletter() && (params.newstype === 'Bookmarked') && routing.isDev() && newsfeed && (newsfeed.length !== 0)">
    <mat-icon *ngIf="!showRecommendations" (click)="showNewsRecommendations(true)" matTooltip="Show similar articles">add</mat-icon>
    <mat-icon *ngIf="showRecommendations" (click)="showNewsRecommendations(false)" matTooltip="Show only bookmarked articles">clear</mat-icon>
  </div>-->

  <div class="scroload" *ngIf="!readyscroll">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<!--<ng-template #recommendationsDialog>
  <div class="dialog-message">{{(newsfeedData.news.length > 0) ? 'Want to find more relevant articles?' : 'No articles found'}}</div>
  <button mat-button (click)="openRecommendationsSetup()" matTooltip="Try the Assisted Bookmarking Tool to find more relevant articles">Try the Assisted Bookmarking Tool</button>
  <div class="dialog-option">
    <mat-checkbox [(ngModel)]="bookmarkingToolPopup" (change)="onPopupChange()">Automatic pop-up</mat-checkbox>
  </div>
</ng-template>-->
