<form class="portfolio-index" (ngSubmit)="saveIndex($event)">
  <mat-form-field
    *ngIf="auth.folio.is_aggregated"
    appearance="outline"
    class="inptbox"
  >
    <mat-label>Portfolio</mat-label>
    <mat-select
      [(ngModel)]="aggregatedPortfolios"
      [ngModelOptions]="{ standalone: true }"
      multiple
    >
      <mat-option
        *ngFor="let portfolio of auth.folios | orderBy: 'name'"
        [value]="portfolio.uid"
        [disabled]="portfolio.uid === auth.folio.uid"
      >
        {{ portfolio.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    *ngIf="!auth.folio.is_aggregated"
    appearance="outline"
    class="inptbox"
  >
    <mat-label>{{ 'MENU_ABOVE.SEARCH' | translate }}...</mat-label>
    <input
      matInput
      type="text"
      [formControl]="myIndex"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="goSelect(myIndex.value)"
      [autoActiveFirstOption]="true"
      panelWidth="auto"
      class="search-panel"
    >
      <div *ngIf="items$ | async as search">
        <div
          *ngFor="
            let items of search | groupBy: 'entity.type' | orderByType | pairs
          "
        >
          <mat-optgroup>
            <div class="optgroup-search">
              <div class="indexo-left">{{ items[0] | nicetxt }}</div>
              <div class="indexo-right">
                <div class="optgroup-column column-1">Country</div>
                <div class="optgroup-column" *ngIf="items[0] !== 'pne'">
                  # Articles
                </div>
              </div>
            </div>
            <ng-container *ngFor="let option of items[1]">
              <mat-option *ngIf="!option.entity.hasParent" [value]="option">
                <div class="portfolio-indexo">
                  <div class="indexo-left">
                    <span>{{ option.entity.name }}</span>
                    <span class="match">{{
                      option.entity.matched_form
                        ? option.entity.matched_form
                        : ''
                    }}</span>
                  </div>
                  <div class="indexo-right">
                    <div class="optgroup-column column-1">
                      {{
                        option.entity &&
                        option.entity.country &&
                        option.entity.country.alpha_id
                          ? option.entity.country.alpha_id.toUpperCase()
                          : '\u2014'
                      }}
                    </div>
                    <div class="optgroup-column" *ngIf="items[0] !== 'pne'">
                      {{ numberFormat(option.count) }}
                    </div>
                  </div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let optionChild of option.entity.children"
                [value]="optionChild"
              >
                <div class="portfolio-indexo">
                  <div class="indexo-left">
                    <span class="subsidiary">{{
                      optionChild.entity.name
                    }}</span>
                    <span class="match">{{
                      optionChild.entity.matched_form
                        ? optionChild.entity.matched_form
                        : ''
                    }}</span>
                  </div>
                  <div class="indexo-right">
                    <div class="optgroup-column column-1">
                      {{
                        optionChild.entity &&
                        optionChild.entity.country &&
                        optionChild.entity.country.alpha_id
                          ? optionChild.entity.country.alpha_id.toUpperCase()
                          : '\u2014'
                      }}
                    </div>
                    <div class="optgroup-column" *ngIf="items[0] !== 'pne'">
                      {{ numberFormat(optionChild.count) }}
                    </div>
                  </div>
                </div>
              </mat-option>
            </ng-container>
          </mat-optgroup>
        </div>
        <mat-optgroup *ngIf="!loadingPNE && itemsPNE.length">
          <div class="optgroup-search">
            <div class="indexo-left">Other Potential Entity</div>
            <div class="indexo-right"></div>
          </div>
          <mat-option
            *ngFor="let option of itemsPNE"
            [value]="{ type: 'pne', pne: option }"
          >
            <div class="portfolio-indexo">
              <div class="indexo-left">
                <span>{{ option.surface }}</span>
                <span class="match">{{ option.type ? option.type : '' }}</span>
              </div>
              <div class="indexo-right">
                <div class="optgroup-column column-1"></div>
                <div class="optgroup-column">
                  {{
                    option.count || option.count === 0
                      ? numberFormat(option.count)
                      : ''
                  }}
                </div>
              </div>
            </div>
          </mat-option>
        </mat-optgroup>
        <mat-optgroup *ngIf="loadingPNE">
          <div class="optgroup-search">
            <div class="indexo-left">Other Potential Entity</div>
            <div class="indexo-right" style="justify-content: end">
              <mat-spinner [diameter]="15"></mat-spinner>
            </div>
          </div>
        </mat-optgroup>
      </div>
    </mat-autocomplete>
  </mat-form-field>
  <div class="action">
    <button mat-raised-button color="primary" type="submit">Save</button>
    <button
      mat-raised-button
      color="warn"
      (click)="deleteIndex()"
      *ngIf="data.action === 'edit'"
    >
      Delete
    </button>
  </div>
</form>
