/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { SupplyChainData } from '../network-data.interface';
import { SupplyChainService } from '../news-network.services/network-data.service';
import { NetworkFilterService } from '../news-network.services/network-filter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-network-title',
  templateUrl: './network-filters.component.html',
  styleUrls: ['./network-filters.component.scss'],
})
export class NetworkFiltersComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private isUpdatingFilters = false;

  @Input() titolo: any;

  params: any;
  networkEntityName: string | null = null;
  suppliersFilters: any;
  parentSupliers: { name: string; value: string }[] = [
    {
      name: 'Vitesco',
      value: 'vitesco_technologies_group_aktiengesellschaft',
    },
    { name: 'Stellantis', value: 'stellantis_nv' },
  ];
  selectedSuplier = 'vitesco_technologies_group_aktiengesellschaft';
  productFilter: string[] = [];
  geographyFilter: string[] = [];
  tierFilter: string[] = [];
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private filterService: NetworkFilterService,
    private supplyChainService: SupplyChainService,
  ) {
    this.resetFilters();
  }

  ngOnInit(): void {
    // Handle query parameter changes
    this.route.queryParams
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((prev, curr) => {
          const relevantParams = [
            'sup_prod',
            'sup_geo',
            'sup_tier',
            'sup_score',
          ];
          return !relevantParams.some((param) => prev[param] !== curr[param]);
        }),
      )
      .subscribe((params) => {
        if (!this.isUpdatingFilters) {
          this.params = params;
          this.updateFiltersFromParams(params);
        }
      });

    this.supplyChainService
      .getSupplyChain()
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => !!data),
        distinctUntilChanged(),
      )
      .subscribe((supplyChain: SupplyChainData | null) => {
        if (supplyChain) {
          this.updateFiltersData(supplyChain);
        }
      });

    this.filterService.filteredData$
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => !!data),
        distinctUntilChanged(),
      )
      .subscribe((filteredData) => {
        if (filteredData) {
          this.networkEntityName = filteredData.motherCompany.entity.name;
        }
      });

    // Subscribe to loading state
    this.supplyChainService.loading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.loading = isLoading;
      });
  }

  filterSuppliers(value: string | number, type: string): void {
    if (this.loading) return;
    this.isUpdatingFilters = true;
    this.filterService.updateFilter(value, type);
    this.isUpdatingFilters = false;
  }

  selectSupplier(value: string): void {
    if (this.selectedSuplier === value || this.loading) return;

    this.selectedSuplier = value;
    this.filterService.resetFilters();
    this.resetFilters();

    this.isUpdatingFilters = true;
    this.router
      .navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_prod: null,
          sup_geo: null,
          sup_tier: null,
          sup_score: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      })
      .then(() => {
        this.supplyChainService.setSelectedEntityName(value).subscribe({
          next: () => {
            this.isUpdatingFilters = false;
          },
          error: (err) => {
            console.error('Error updating supply chain data:', err);
            this.isUpdatingFilters = false;
          },
        });
      });
  }

  private resetFilters(): void {
    this.suppliersFilters = {
      product: 'All',
      geography: 'All',
      tier: 'All',
      score: 0,
    };
  }

  private updateFiltersFromParams(params: any): void {
    let supFilterScore = 0;
    if (params.sup_score) {
      if (params.sup_score === '0-2') supFilterScore = 1;
      else if (params.sup_score === '2-4') supFilterScore = 2;
      else if (params.sup_score === '4-6') supFilterScore = 3;
      else if (params.sup_score === '6-8') supFilterScore = 4;
      else if (params.sup_score === '8-10') supFilterScore = 5;
    }

    this.suppliersFilters = {
      product: params.sup_prod ?? 'All',
      geography: params.sup_geo ?? 'All',
      tier: params.sup_tier ?? 'All',
      score: supFilterScore,
    };
  }

  private updateFiltersData(supplyChain: SupplyChainData): void {
    if (!supplyChain) return;

    const { motherCompany, suppliers } = supplyChain;
    this.networkEntityName = motherCompany.entity.name;

    this.geographyFilter = [];
    this.productFilter = [];

    this.geographyFilter = [...new Set([...motherCompany.countries])];

    suppliers.forEach((supplier) => {
      this.productFilter = [
        ...new Set([...this.productFilter, ...supplier.supplied_products]),
      ];

      this.geographyFilter = [
        ...new Set([...this.geographyFilter, ...supplier.countries]),
      ];
    });

    this.filterService.setInitialData(supplyChain);
  }
  sliderLabel(value: number): string {
    if (value === 0) {
      return 'All';
    } else if (value === 1) {
      return '0-2';
    } else if (value === 2) {
      return '2-4';
    } else if (value === 3) {
      return '4-6';
    } else if (value === 4) {
      return '6-8';
    } else if (value === 5) {
      return '8-10';
    }
    return `${value}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
