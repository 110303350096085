/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  tap,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  ParentCompany,
  SupplyChainData,
  SupplierInfo,
} from '../network-data.interface';
import { YukkApi } from '../../../../service/yukkapi.service';

@Injectable({
  providedIn: 'root',
})
export class SupplyChainService {
  private _selectedEntityName = new BehaviorSubject<string>(
    'vitesco_technologies_group_aktiengesellschaft',
  );
  private supplyChainSubject = new BehaviorSubject<SupplyChainData | null>(
    null,
  );
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private dataFetchInProgress: Observable<SupplyChainData> | null = null;

  public loading$ = this.loadingSubject.asObservable();
  public supplyChain$ = this.supplyChainSubject
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1));

  constructor(private yukkApi: YukkApi) {
    this._selectedEntityName.pipe(distinctUntilChanged()).subscribe(() => {
      this.fetchSupplyChain();
    });
  }

  private fetchSupplyChain(): Observable<SupplyChainData> {
    if (this.dataFetchInProgress) {
      return this.dataFetchInProgress;
    }

    this.loadingSubject.next(true);
    const entity = `company:${this._selectedEntityName.value}`;

    // Fetch parent company and suppliers...
    const fetchOperation = forkJoin({
      parentCompany: this.fetchParentCompany(entity),
      suppliers: this.fetchSuppliers(entity),
    }).pipe(
      map(({ parentCompany, suppliers }) => ({
        motherCompany: parentCompany,
        suppliers,
      })),
      tap({
        next: (data) => {
          this.supplyChainSubject.next(data);
          this.loadingSubject.next(false);
          this.dataFetchInProgress = null;
        },
        error: (error) => {
          console.error('Error fetching supply chain data:', error);
          this.loadingSubject.next(false);
          this.dataFetchInProgress = null;
        },
      }),
      shareReplay(1),
    );

    this.dataFetchInProgress = fetchOperation;
    fetchOperation.subscribe();

    return fetchOperation;
  }

  private fetchParentCompany(entity: string): Observable<ParentCompany> {
    return this.yukkApi.getEntitiesInfo([entity], 'array').pipe(
      map((res) => {
        const parent = res[0];
        return {
          entity: {
            type: parent.type,
            alpha_id: parent.alpha_id,
            name: parent.name,
            shortName: parent.name.split(' ')[0],
            compound_key: parent.compound_key,
          },
          num_employees: parent.description?.Num_of_employees || null,
          countries: [parent.description?.Country?.name].filter(Boolean),
          cities: [parent.description?.City?.name].filter(Boolean),
          industries: [parent.description?.Industry?.name].filter(Boolean),
          supersectors: [parent.description?.Supersector?.name].filter(Boolean),
          sectors: [parent.description?.Sector?.name].filter(Boolean),
          subsectors: [parent.description?.Subsector?.name].filter(Boolean),
          persons: [parent.description?.CEO?.name].filter(Boolean),
        };
      }),
    );
  }

  private fetchSuppliers(entity: string): Observable<SupplierInfo[]> {
    return this.yukkApi.getSupplyChainInfo(entity, 150).pipe(
      switchMap((suppliers) => {
        if (suppliers.length === 0) return of([]);

        const compoundKeys = suppliers.map((supplier) => supplier.compound_key);
        return this.yukkApi
          .getEntitiesInfo(compoundKeys, 'array')
          .pipe(map((entities) => this.mapSuppliersData(suppliers, entities)));
      }),
    );
  }

  getSupplyChain(): Observable<SupplyChainData | null> {
    if (!this.supplyChainSubject.value) {
      return this.fetchSupplyChain();
    }
    return this.supplyChain$;
  }

  setSelectedEntityName(
    entityName: string,
  ): Observable<SupplyChainData | null> {
    if (this._selectedEntityName.value !== entityName) {
      this._selectedEntityName.next(entityName);
    }
    return this.supplyChain$;
  }

  clearData(): void {
    this.supplyChainSubject.next(null);
    this._selectedEntityName.next(
      'vitesco_technologies_group_aktiengesellschaft',
    );
  }

  private mapSuppliersData(suppliers: any[], entities: any[]): SupplierInfo[] {
    const entityDict = entities.reduce((acc, entity) => {
      if (entity?.compound_key) {
        acc[entity.compound_key] = entity;
      }
      return acc;
    }, {});

    return suppliers.map((supplier) => {
      const entity = entityDict[supplier.compound_key] || null;
      return {
        entity: entity
          ? {
              type: entity.type,
              alpha_id: entity.alpha_id,
              name: entity.name,
              shortName: entity.name.split(' ')[0],
              compound_key: entity.compound_key,
            }
          : null,
        num_employees: entity?.description?.Num_of_employees || null,
        supplied_products: supplier.supplied_products.map((item) =>
          item.product.alpha_id?.replace('_', ' '),
        ),
        countries: [entity?.description?.Country?.name].filter(Boolean),
        cities: [entity?.description?.City?.name].filter(Boolean),
        industries: [entity?.description?.Industry?.name].filter(Boolean),
        supersectors: [entity?.description?.Supersector?.name].filter(Boolean),
        sectors: [entity?.description?.Sector?.name].filter(Boolean),
        subsectors: [entity?.description?.Subsector?.name].filter(Boolean),
        persons: [entity?.description?.CEO?.name].filter(Boolean),
      };
    });
  }
}
