/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-loader',
  templateUrl: './chat-loader.component.html',
  styleUrls: ['./chat-loader.component.scss'],
})
export class ChatLoaderComponent implements OnInit {
  text: string = '';
  currentStep: number = 0;
  isShortLoading: boolean = true;

  step1 = [
    'Analyzing your query',
    'Sorting results by relevance',
    'Searching the web',
  ];
  step2 = [
    'Gathering data',
    'Synthesizing insights',
    'Working through the details',
  ];
  step3 = [
    'Finalizing the answer',
    'Preparing a detailed answer',
    'Wrapping things up',
  ];

  steps = [this.step1, this.step2, this.step3];

  private timeoutRef: any;
  private intervalRef: any;

  constructor() {}

  ngOnInit(): void {
    this.startLoader();
  }

  startLoader() {
    this.timeoutRef = setTimeout(() => {
      this.isShortLoading = false;
      this.startLongLoading();
    }, 4000);
  }

  startLongLoading() {
    this.updateText();
    this.intervalRef = setInterval(() => this.updateText(), 5000);
  }
  updateText() {
    if (this.currentStep < this.steps.length) {
      const currentArray = this.steps[this.currentStep];

      const randomString =
        currentArray[Math.floor(Math.random() * currentArray.length)];

      this.text = randomString;
      this.currentStep++;
    }
  }
}
