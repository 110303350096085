<div class="news-chartmap" [class.mobile]="routing.isMobile()">
  <div
    class="chart-main"
    id="chartcanvas"
    *ngIf="!routing.isNetwork()"
    [class.mobile]="routing.isMobile()"
    [class.portfolio]="routing.isFolio()"
    [class.query]="routing.isQuery() || routing.isSearch()"
    [class.fulview]="(params.folio || params.query) && !routing.isNewsaudit()"
    [class.audit]="routing.isNewsaudit()"
    [class.score-focus]="
      routing.isChart() && (routing.isFolio() || routing.isMarket())
    "
    [class.score-focused]="params?.score"
  >
    <app-news-chart></app-news-chart>
  </div>
  <div
    class="chart-main"
    id="chartcanvas2"
    *ngIf="routing.isNetwork()"
    [class.mobile]="routing.isMobile()"
    [class.portfolio]="routing.isFolio()"
    [class.query]="routing.isQuery() || routing.isSearch()"
    [class.fulview]="(params.folio || params.query) && !routing.isNewsaudit()"
    [class.audit]="routing.isNewsaudit()"
    [class.score-focus]="
      routing.isNetwork() && (routing.isFolio() || routing.isMarket())
    "
    [class.score-focused]="params?.score"
    [class.network]="routing.isNetwork()"
  >
    <app-network></app-network>
  </div>
  <div
    class="chart-treemap"
    [class.hide]="
      params.type === 'hierarchy' ||
      params.folio ||
      params.query ||
      routing.isNewsaudit()
    "
    *ngIf="
      params.type !== 'pne' &&
      params.type !== 'company' &&
      params.type !== 'hierarchy' &&
      !['industry', 'supersector', 'sector', 'subsector'].includes(
        params.type
      ) &&
      !(routing.isFolio() && (routing.isChart() || routing.isNetwork())) &&
      !(routing.isQuery() && routing.isChart()) &&
      !(routing.isSearch() && routing.isChart())
    "
  >
    <app-news-treemap *ngIf="!routing.isNewsaudit()"></app-news-treemap>
  </div>
  <div
    *ngIf="
      routing.isChart() &&
      (routing.isFolio() || routing.isMarket()) &&
      params?.score
    "
    class="news-chartmap-divider"
    [style.border-color]="auth.scoreFocusedColor"
  ></div>
  <div
    class="chart-company"
    *ngIf="
      ((params.type === 'pne' ||
        params.type === 'company' ||
        params.type === 'hierarchy' ||
        ['industry', 'supersector', 'sector', 'subsector'].includes(
          params.type
        )) &&
        !routing.isNewsaudit()) ||
      (routing.isFolio() && routing.isChart()) ||
      (routing.isFolio() && routing.isNetwork()) ||
      (routing.isQuery() && routing.isChart()) ||
      (routing.isSearch() && routing.isChart())
    "
    [class.inblur]="params.tutorial === 'chart'"
    [class.score-focus]="
      (routing.isChart() || routing.isNetwork()) &&
      (routing.isFolio() || routing.isMarket())
    "
    [class.score-focused]="params?.score"
    [class.network]="routing.isNetwork()"
    [style.border-color]="auth.scoreFocusedColor"
  >
    <app-chartmap-company></app-chartmap-company>
  </div>
  <div class="chart-info" *ngIf="routing.isNewsaudit()">
    <app-chartmap-info></app-chartmap-info>
  </div>
</div>
