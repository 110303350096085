<div class="letter-form" *ngIf="newsletter">
  <div class="form-header">Settings</div>

  <div class="form-main">
    <div class="form-fildo">
      <mat-form-field class="field-title">
        <mat-label>Name</mat-label>
        <input
          #inputNewsletterTitle
          matInput
          type="text"
          [(ngModel)]="newsletter.title"
          autocomplete="off"
          required
        />
      </mat-form-field>
      <div class="space"></div>
      <mat-form-field
        class="slide-toggle"
        floatLabel="always"
        appearance="none"
      >
        <mat-label>{{ newsletter.active ? 'Active' : 'Inactive' }}</mat-label>
        <mat-slide-toggle [(ngModel)]="newsletter.active"></mat-slide-toggle>
        <textarea matInput hidden></textarea>
      </mat-form-field>
    </div>

    <div class="form-fildo">
      <mat-form-field>
        <mat-label>Frequency</mat-label>
        <mat-select
          #selectorNewsletterFrequency
          [(ngModel)]="newsletter.frequency"
          required
        >
          <mat-option *ngFor="let item of frequency" [value]="item.value">{{
            item.valueName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-fildo multiple">
      <div
        class="displayable-container"
        *ngFor="let displayable of newsletter.dispObjects; index as index"
      >
        <mat-form-field class="field-type">
          <mat-label>Content</mat-label>
          <mat-select
            [(ngModel)]="newsletter.dispObjects[index].type"
            (selectionChange)="optSelect2Array(index)"
            required
          >
            <mat-option value="entity">Entity</mat-option>
            <mat-option value="portfolio">Portfolio</mat-option>
            <mat-option value="search_query">Query</mat-option>
            <mat-option value="search_query_group">Collection</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="space"></div>

        <mat-form-field
          class="field-search"
          *ngIf="newsletter.dispObjects[index].type === 'portfolio'"
        >
          <mat-label>Select</mat-label>
          <mat-select
            #selectorNewsletterObject
            [(ngModel)]="newsletter.dispObjects[index]['payload']"
            (selectionChange)="optSelect3Array(index, $event, 'portfolio')"
            required
          >
            <mat-option
              *ngFor="let option of folio$ | async | orderBy: 'name'"
              [value]="option.uid"
              >{{ option.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <!--<mat-form-field class="field-search" *ngIf="newsletter.dispObjects[index].type=='portfolio'">
          <mat-label>Name</mat-label>
          <input matInput type="text" [formControl]="portfolioFormControls[index]" [matAutocomplete]="autoPortfolio" required>
          <mat-autocomplete #autoPortfolio="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let portfolio of filteredPortfolios$[index] | async | orderBy: 'name'" [value]="portfolio.name">
              {{portfolio.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>-->

        <!--<mat-form-field class="field-search" *ngIf="newsletter.dispObjects[index].type=='search_query'">
          <mat-label>Name</mat-label>
          <mat-select [(ngModel)]="newsletter.dispObjects[index].payload" (selectionChange)="optSelect3Array(index, $event, 'query')" required>
            <mat-option *ngFor="let option of query$|async" [value]="option.uid">{{option.name}}</mat-option>
          </mat-select>
        </mat-form-field>-->

        <mat-form-field
          class="field-search"
          *ngIf="newsletter.dispObjects[index].type === 'search_query'"
        >
          <mat-label>Search</mat-label>
          <input
            #inputNewsletterObject
            matInput
            type="text"
            [formControl]="queryFormControls[index]"
            [matAutocomplete]="autoQuery"
            required
          />
          <mat-autocomplete
            #autoQuery="matAutocomplete"
            [displayWith]="displayFn"
          >
            <mat-option
              *ngFor="
                let query of filteredQueries$[index] | async | orderBy: 'name'
              "
              [value]="query"
            >
              {{ query.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          class="field-search"
          *ngIf="newsletter.dispObjects[index].type === 'search_query_group'"
        >
          <mat-label>Select</mat-label>
          <mat-select
            #selectorNewsletterObject
            [(ngModel)]="newsletter.dispObjects[index].payload"
            (selectionChange)="optSelect3Array(index, $event, 'collection')"
            required
          >
            <mat-option
              *ngFor="let option of collection$ | async | orderBy: 'name'"
              [value]="option.uid"
              >{{ option.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="field-search"
          *ngIf="newsletter.dispObjects[index].type === 'entity'"
        >
          <mat-label>Search</mat-label>
          <input
            #inputNewsletterObject
            matInput
            type="text"
            [matAutocomplete]="menuSearch"
            [formControl]="searchFormControls[index]"
            required
          />
          <!--<div class="iresult" *ngIf="!loading && (item$|async)?.entity_prefix_search.hits.length>1">
            {{(item$|async)?.entity_prefix_search.hits.length}} results
          </div>-->
          <mat-autocomplete
            #menuSearch="matAutocomplete"
            (optionSelected)="optSelectArray(index, $event.option.value)"
            [autoActiveFirstOption]="true"
            class="searchLetter"
            panelWidth="400px"
          >
            <div *ngIf="items$[index] | async as search">
              <div
                *ngFor="
                  let items of search.hits
                    | groupBy: 'entity.type'
                    | orderByType
                    | pairs
                "
              >
                <mat-optgroup>
                  <div class="optgroup-search">
                    <div class="indexo-left">{{ items[0] | nicetxt }}</div>
                    <div class="indexo-right">
                      <div class="optgroup-column column-1">Country</div>
                      <div class="optgroup-column"># Articles</div>
                    </div>
                  </div>
                  <ng-container *ngFor="let option of items[1]">
                    <mat-option
                      *ngIf="!option.entity.hasParent"
                      [value]="option"
                    >
                      <div class="search-indexo">
                        <div class="indexo-left">
                          <span>{{ option.entity.name }}</span>
                          <span class="match" *ngIf="iMatch(option.entity)">{{
                            option.entity.matched_form
                              ? option.entity.matched_form
                              : ''
                          }}</span>
                        </div>
                        <div class="indexo-right">
                          <div class="optgroup-column column-1">
                            {{
                              option.entity &&
                              option.entity.country &&
                              option.entity.country.alpha_id
                                ? option.entity.country.alpha_id.toUpperCase()
                                : '\u2014'
                            }}
                          </div>
                          <div class="optgroup-column">
                            {{ numberFormat(option.count) }}
                          </div>
                        </div>
                      </div>
                    </mat-option>
                    <mat-option
                      *ngFor="let optionChild of option.entity.children"
                      [value]="optionChild"
                    >
                      <div class="search-indexo">
                        <div class="indexo-left">
                          <span class="subsidiary">{{
                            optionChild.entity.name
                          }}</span>
                          <span
                            class="match"
                            *ngIf="iMatch(optionChild.entity)"
                            >{{
                              optionChild.entity.matched_form
                                ? optionChild.entity.matched_form
                                : ''
                            }}</span
                          >
                        </div>
                        <div class="indexo-right">
                          <div class="optgroup-column column-1">
                            {{
                              optionChild.entity &&
                              optionChild.entity.country &&
                              optionChild.entity.country.alpha_id
                                ? optionChild.entity.country.alpha_id.toUpperCase()
                                : '\u2014'
                            }}
                          </div>
                          <div class="optgroup-column">
                            {{ numberFormat(optionChild.count) }}
                          </div>
                        </div>
                      </div>
                    </mat-option>
                  </ng-container>
                </mat-optgroup>
              </div>
            </div>
          </mat-autocomplete>
          <mat-spinner
            [diameter]="15"
            *ngIf="loadingArray[index]"
          ></mat-spinner>
        </mat-form-field>
        <div class="space"></div>
        <div
          class="btndel"
          (click)="delDisplayable(index)"
          *ngIf="newsletter.dispObjects.length > 1"
        >
          <mat-icon>remove_circle</mat-icon>
        </div>
      </div>
      <div class="btnadd">
        <button mat-button (click)="addDisplayable()">
          <mat-icon>add_circle</mat-icon>
          <span>ADD</span>
        </button>
      </div>
    </div>

    <div class="form-fildo">
      <mat-form-field>
        <mat-label>Content Format</mat-label>
        <mat-select
          #selectorNewsletterContents
          [(ngModel)]="setContents"
          required
          (selectionChange)="onContentsChange(setContents)"
        >
          <mat-option *ngFor="let item of include" [value]="item.value">{{
            item.valueName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="space"></div>
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <mat-select [(ngModel)]="newsletter.cockpit_filter_id">
          <mat-option>None</mat-option>
          <mat-option
            *ngFor="let item of filter$ | async"
            [value]="item.id"
            [class.hidden]="item.name === '_default_'"
            >{{ item.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-fildo">
      <mat-form-field>
        <mat-label>Design</mat-label>
        <mat-select
          multiple
          [(ngModel)]="setDesign"
          (selectionChange)="onDesignChange(setDesign)"
        >
          <mat-option *ngFor="let item of design" [value]="item.value">{{
            item.valueName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="space" *ngIf="setDesign.includes('chart')"></div>
      <mat-form-field *ngIf="setDesign.includes('chart')">
        <mat-label>Score Type</mat-label>
        <mat-select
          [(ngModel)]="setScoreType"
          [placeholder]="setScoreType"
          (selectionChange)="onScoreTypeChange(setScoreType)"
        >
          <mat-option *ngFor="let item of scores" [value]="item">{{
            auth.getScoresInfo(item)['name']
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-fildo multiple" *ngIf="setContents === 'recommended_news'">
      <div class="header">
        <span>Recommended News Settings</span
        ><span class="info-label">BETA</span>
      </div>
      <div class="displayable-container">
        <mat-form-field>
          <mat-label>Phrase</mat-label>
          <input
            #inputNewsletterPhrase
            matInput
            type="text"
            [(ngModel)]="newsletter.recommended_news_phrases[0]"
            autocomplete="off"
            required
          />
        </mat-form-field>
      </div>
      <div class="displayable-container">
        <mat-form-field>
          <mat-label>No. Articles</mat-label>
          <input
            type="number"
            min="1"
            step="1"
            required
            matInput
            [(ngModel)]="newsletter.recommended_news_num_articles"
            (change)="
              newsletter.recommended_news_num_articles < 1
                ? (newsletter.recommended_news_num_articles = 1)
                : newsletter.recommended_news_num_articles
            "
            name="threshold"
          />
          <mat-error>Please enter an integer above 0</mat-error>
        </mat-form-field>
        <div class="space"></div>
        <mat-form-field class="slide" floatLabel="always" appearance="none">
          <mat-label
            >Similarity:
            {{ newsletter.recommended_news_threshold | percent }}</mat-label
          >
          <mat-slider
            min="0"
            max="1"
            step="0.01"
            [(ngModel)]="newsletter.recommended_news_threshold"
          ></mat-slider>
          <textarea matInput hidden></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="form-fildo multiple" *ngIf="setContents === 'all_news'">
      <div class="header"><span>All News Settings</span></div>
      <div class="displayable-container">
        <mat-form-field>
          <mat-label>No. Articles</mat-label>
          <input
            type="number"
            min="1"
            step="1"
            required
            matInput
            [(ngModel)]="newsletter.all_news_max_number"
            (change)="
              newsletter.all_news_max_number < 1
                ? (newsletter.all_news_max_number = 1)
                : newsletter.all_news_max_number
            "
            name="threshold"
          />
          <mat-error>Please enter an integer above 0</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-fildo multiple" *ngIf="setContents === 'top_news'">
      <div class="header"><span>Top News Settings</span></div>
      <div class="displayable-container">
        <mat-form-field>
          <mat-label>No. Articles</mat-label>
          <input
            type="number"
            min="1"
            step="1"
            required
            matInput
            [(ngModel)]="newsletter.top_news_max_number"
            (change)="
              newsletter.top_news_max_number < 1
                ? (newsletter.all_news_max_number = 1)
                : newsletter.top_news_max_number
            "
            name="threshold"
          />
          <mat-error>Please enter an integer above 0</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-fildo multiple" *ngIf="setContents === 'stories'">
      <div class="header"><span>Stories Settings</span></div>
      <div class="displayable-container">
        <mat-form-field>
          <mat-label>No. Articles</mat-label>
          <input
            type="number"
            min="1"
            step="1"
            required
            matInput
            [(ngModel)]="newsletter.stories_max_number"
            (change)="
              newsletter.stories_max_number < 1
                ? (newsletter.all_news_max_number = 1)
                : newsletter.stories_max_number
            "
            name="threshold"
          />
          <mat-error>Please enter an integer above 0</mat-error>
        </mat-form-field>
      </div>
    </div>

    <!--<div class="form-fildo">
      <mat-form-field appearance="outline" class="field-title">
        <mat-label>Subscription Email</mat-label>
        <mat-select [(value)]="selectedEmail">
          <mat-option value="myEmail">{{email}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>-->

    <div class="form-fildo">
      <mat-form-field class="field-title">
        <mat-label>Sender Name</mat-label>
        <input
          #inputSenderName
          matInput
          type="text"
          [(ngModel)]="newsletter.from_email_displayname"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="form-fildo">
      <mat-form-field>
        <mat-label>Additional Recipients</mat-label>
        <mat-chip-list #chipList aria-label="Email selection">
          <mat-chip
            *ngFor="let item of emailList"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(item)"
          >
            {{ item }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            matInput
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>

    <div class="form-fildo divider">
      <span>Additional Options</span>
    </div>

    <div class="form-fildo">
      <mat-form-field class="field-title">
        <mat-label>Banner (max size 2.5 MB)</mat-label>
        <input
          matInput
          [readonly]="auth.featureFlags.newsletterBanner !== ''"
          type="text"
          placeholder="http://..."
          [(ngModel)]="newsletter.header_image"
        />
      </mat-form-field>
      <div
        class="space"
        *ngIf="auth.featureFlags.newsletterBanner === ''"
      ></div>
      <button
        mat-button
        *ngIf="auth.featureFlags.newsletterBanner === ''"
        (click)="header.click()"
      >
        <mat-icon>cloud_upload</mat-icon>
      </button>
      <input
        class="inptfile"
        type="file"
        accept=".png,.jpg"
        (change)="inHeader($event)"
        #header
      />
    </div>

    <div class="form-fildo">
      <mat-form-field class="field-title">
        <mat-label>Logo (max size 2.5 MB)</mat-label>
        <input
          matInput
          [readonly]="auth.featureFlags.newsletterLogo !== ''"
          type="text"
          placeholder="http://..."
          [(ngModel)]="newsletter.logo"
        />
      </mat-form-field>
      <div class="space" *ngIf="auth.featureFlags.newsletterLogo === ''"></div>
      <button
        mat-button
        *ngIf="auth.featureFlags.newsletterLogo === ''"
        (click)="logo.click()"
      >
        <mat-icon>cloud_upload</mat-icon>
      </button>
      <input
        class="inptfile"
        type="file"
        accept=".png,.jpg"
        (change)="inLogo($event)"
        #logo
      />
    </div>

    <div class="form-fildo signature">
      <mat-form-field>
        <mat-label>Signature</mat-label>
        <mat-select
          [(value)]="selectedTemplate"
          (selectionChange)="getTemplate($event)"
        >
          <mat-option *ngFor="let item of mjmlTemplates" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
        <mat-hint
          >Please choose a template and use
          <a
            href="https://mjml.io/documentation/"
            target="_blank"
            rel="noopener noreferrer"
            >mjml</a
          >
          syntax</mat-hint
        >
      </mat-form-field>
      <div class="space"></div>
      <button
        mat-button
        [class.active]="showCode"
        (click)="toggleEditor()"
        matTooltip="Switch between editor and code views"
      >
        <mat-icon>code</mat-icon>
      </button>
    </div>

    <div
      class="form-fildo editor"
      [class.hideEditor]="hideSignature"
      style="padding-bottom: 20px"
    >
      <div id="myFrame" width="100%" #myFrame [hidden]="true"></div>
      <div id="gjsCode" [hidden]="hideSignature || !showCode">
        <textarea
          matInput
          [(ngModel)]="newsletter.signature_footer"
          (click)="$event.stopPropagation()"
          cdkTextareaAutosize
        ></textarea>
      </div>
      <div id="gjs" #gjs [hidden]="hideSignature || showCode"></div>
      <div id="blocks" #blocks [hidden]="hideSignature || showCode"></div>
    </div>

    <div class="form-fildo">
      <mat-form-field>
        <mat-label>Custom Disclaimer</mat-label>
        <textarea
          matInput
          [readonly]="auth.featureFlags.newsletterDisclaimer !== ''"
          [(ngModel)]="newsletter.disclaimer"
          cdkTextareaAutosize
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="form-acto">
    <button
      mat-raised-button
      class="btnflex"
      (click)="onSubmit('test')"
      [disabled]="iupload"
      matTooltip="Send newsletter now"
    >
      <span>{{ iupload ? 'LOADING' : 'SEND' }}</span>
    </button>

    <div class="space"></div>

    <button
      mat-raised-button
      class="btnflex save"
      (click)="onSubmit('save')"
      [disabled]="iupload"
    >
      <span>{{ iupload ? 'LOADING' : 'SAVE' }}</span>
      <!-- <mat-icon *ngIf="!iupload">save_alt</mat-icon>
        <mat-spinner *ngIf="iupload" [diameter]="25"></mat-spinner> -->
    </button>
  </div>
</div>
