<div class="chat__welcome-container">
  <div class="chat_welcome-header">
    <div class="chat__welcome-logo">
      <img src="assets/logo/yukka_mobile.svg" alt="Logo" class="chat__logo" />
    </div>
    <div class="chat__user">
      <div class="chat__user-title">Hello {{ userName }}</div>
      <div class="chat__user-subtitle">What can I help you with today?</div>
    </div>
  </div>
  <div class="chat__questions">
    <ng-container *ngFor="let item of preDefinedQuestions">
      <div class="chat__questions-container">
        <div class="chat__questions-title">{{ item.title }}</div>
        <div class="chat__questions-list">
          <div
            class="chat__question-box"
            *ngFor="let quest of item.questions"
            (click)="onQuestionClick(quest.question)"
          >
            <div class="chat__question-box-header">
              <div class="chat__question-icon">
                <mat-icon class="icon">{{ quest.icon }}</mat-icon>
              </div>
              <div class="chat__question-header-text">{{ quest.header }}</div>
            </div>
            <div class="chat__question-box-text">{{ quest.question }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
