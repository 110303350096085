import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alerts-setup-delta',
  templateUrl: './alerts-setup-delta.component.html',
  styleUrls: ['./alerts-setup-delta.component.scss'],
})
export class AlertsSetupDeltaComponent {
  @Input() trigger;
  @Input() selectedDeltaPeriod;
  @Input() deltaPeriods;

  onOutOfRange(trigger, type) {
    let maxRange;
    if (type === 'score') {
      maxRange = 1.5;
    } else if (type === 'sentiment') {
      maxRange = 15;
    }
    if (trigger.additional_params.z_score_threshold < 0) {
      trigger.additional_params.z_score_threshold = 0;
    } else if (trigger.additional_params.z_score_threshold > maxRange) {
      trigger.additional_params.z_score_threshold = 0;
    }
  }
}
