import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SupplyChainData } from '../network-data.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NetworkFilterService {
  private filters = {
    score: '0',
    product: 'All',
    geography: 'All',
    tier: 'All',
  };

  private originalData: SupplyChainData | null = null; // Stores the unfiltered data

  private filteredDataSubject = new BehaviorSubject<SupplyChainData | null>(
    null,
  );
  public filteredData$ = this.filteredDataSubject.asObservable();

  constructor(private router: Router) {}

  // Set the initial data
  setInitialData(data: SupplyChainData): void {
    this.originalData = data;
    this.filteredDataSubject.next(data); // Emit the unfiltered data initially
  }

  resetFilters(): void {
    this.filters = {
      score: '0',
      product: 'All',
      geography: 'All',
      tier: 'All',
    };
    if (this.originalData) {
      this.filteredDataSubject.next(this.originalData);
    }
  }

  // Update a specific filter and apply filtering logic
  updateFilter(value: string | number, type: string): void {
    this.filters[type] = value;
    this.applyFilters();
    this.filterSuppliers(value, type);
  }

  // Apply the filters to the original data
  private applyFilters(): void {
    if (!this.originalData) {
      return;
    }

    const filteredSuppliers = this.originalData.suppliers.filter((supplier) => {
      // Apply each filter
      const matchesScore =
        this.filters.score === '0' ||
        (typeof this.filters.score === 'number' &&
          supplier.num_employees === this.filters.score);

      const matchesProduct =
        this.filters.product === 'All' ||
        supplier.supplied_products.includes(this.filters.product as string);

      const matchesGeography =
        this.filters.geography === 'All' ||
        supplier.countries.includes(this.filters.geography as string);

      const matchesTier =
        this.filters.tier === 'All' ||
        (supplier.entity?.alpha_id || '') === this.filters.tier;

      return matchesScore && matchesProduct && matchesGeography && matchesTier;
    });

    // Emit the filtered data
    this.filteredDataSubject.next({
      motherCompany: this.originalData.motherCompany,
      suppliers: filteredSuppliers,
    });
  }

  filterSuppliers(value, type) {
    if (type === 'product') {
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_prod: value === 'All' ? null : value,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else if (type === 'geography') {
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_geo: value === 'All' ? null : value,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else if (type === 'tier') {
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_tier: value === 'All' ? null : value,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else if (type === 'score') {
      const label = this.sliderLabel(value);
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: null,
          sup_score: label === 'All' ? null : label,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  sliderLabel(value: number): string {
    if (value === 0) {
      return 'All';
    } else if (value === 1) {
      return '0-2';
    } else if (value === 2) {
      return '2-4';
    } else if (value === 3) {
      return '4-6';
    } else if (value === 4) {
      return '6-8';
    } else if (value === 5) {
      return '8-10';
    }
    return `${value}`;
  }
}
