<div class="network-main" [class.widget]="routing.isWidget()">
  <app-network-title
    *ngIf="!routing.isWidget()"
    [titolo]="titolo"
  ></app-network-title>
  <div class="network-graph">
    <div class="network-graph-inner">
      <div class="loading" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <div class="nodata" *ngIf="error">
        Something went wrong. Please try to refresh the page
      </div>
      <div class="nodata" *ngIf="nodata">NOT ENOUGH DATA</div>
      <div class="menutool">
        <div class="cont">
          <button
            mat-icon-button
            (click)="inzoom(1.2)"
            matTooltip="Zoom In"
            matTooltipPosition="right"
          >
            <mat-icon>zoom_in</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="inzoom(0.8)"
            matTooltip="Zoom Out"
            matTooltipPosition="right"
          >
            <mat-icon>zoom_out</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="refresh()"
            matTooltip="Refresh"
            matTooltipPosition="right"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
      <div id="graph"></div>

      <div class="suppliers-legend">
        <div class="suppliers-legend-left">
          <div class="suppliers-score-name">ESG score</div>
          <div class="suppliers-score-bar-container">
            <div>0</div>
            <div class="suppliers-score-bar"></div>
            <div>10</div>
          </div>
        </div>
        <div class="suppliers-legend-right">
          <div class="suppliers-legend-line"></div>
          <div class="suppliers-legend-text">Supply chain</div>
          <div class="suppliers-legend-dashed-line"></div>
          <div class="suppliers-legend-text">Supplied by</div>
          <div class="suppliers-legend-circle"></div>
          <div class="suppliers-legend-text">Product</div>
        </div>
      </div>
    </div>
  </div>
</div>
