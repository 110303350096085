<div class="setup-main" data-testid="portfolio-setup">
  <div class="setup-container">
    <div class="setup-contents">
      <div class="setup-header">Welcome to Yukka!</div>

      <div class="setup-info-title">Set up your customized portfolio</div>

      <div class="setup-info-subtitle">
        Add companies to get tailored insights and stay updated on score
        changes.
      </div>

      <mat-form-field appearance="outline" class="field-type">
        <mat-label>Portfolio name</mat-label>
        <input
          matInput
          placeholder="Create portfolio's name..."
          required
          [(ngModel)]="portfolioName"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="field-search">
        <mat-label>Add companies</mat-label>
        <input
          matInput
          placeholder="Search for a company..."
          type="text"
          [matAutocomplete]="menuSearch"
          [formControl]="searchFormControl"
          required
        />
        <mat-autocomplete
          #menuSearch="matAutocomplete"
          (optionSelected)="optSelect($event.option.value)"
          [autoActiveFirstOption]="true"
          class="searchLetter search-panel"
          panelWidth="auto"
        >
          <div *ngIf="items$ | async as search">
            <div
              *ngFor="
                let items of search.hits
                  | filterBy: ['entity.type'] : 'company'
                  | groupBy: 'entity.type'
                  | orderByType
                  | pairs
              "
            >
              <mat-optgroup>
                <div class="optgroup-search">
                  <div class="indexo-left">{{ items[0] | nicetxt: false }}</div>
                  <div class="indexo-right">
                    <div class="optgroup-column column-1">Country</div>
                    <div class="optgroup-column"># Articles</div>
                  </div>
                </div>
                <ng-container *ngFor="let option of items[1]">
                  <mat-option *ngIf="!option.entity.hasParent" [value]="option">
                    <div class="search-indexo">
                      <div class="indexo-left">
                        <span>{{ option.entity.name }}</span>
                        <span class="match">{{
                          option.entity.matched_form
                            ? option.entity.matched_form
                            : ''
                        }}</span>
                      </div>
                      <div class="indexo-right">
                        <div class="optgroup-column column-1">
                          {{
                            option.entity &&
                            option.entity.country &&
                            option.entity.country.alpha_id
                              ? option.entity.country.alpha_id.toUpperCase()
                              : '\u2014'
                          }}
                        </div>
                        <div class="optgroup-column">
                          {{ numberFormat(option.count) }}
                        </div>
                      </div>
                    </div>
                  </mat-option>
                  <mat-option
                    *ngFor="let optionChild of option.entity.children"
                    [value]="optionChild"
                  >
                    <div class="search-indexo">
                      <div class="indexo-left">
                        <span class="subsidiary">{{
                          optionChild.entity.name
                        }}</span>
                        <span
                          class="match"
                          *ngIf="iMatch(optionChild.entity)"
                          >{{
                            optionChild.entity.matched_form
                              ? optionChild.entity.matched_form
                              : ''
                          }}</span
                        >
                      </div>
                      <div class="indexo-right">
                        <div class="optgroup-column column-1">
                          {{
                            optionChild.entity &&
                            optionChild.entity.country &&
                            optionChild.entity.country.alpha_id
                              ? optionChild.entity.country.alpha_id.toUpperCase()
                              : '\u2014'
                          }}
                        </div>
                        <div class="optgroup-column">
                          {{ numberFormat(optionChild.count) }}
                        </div>
                      </div>
                    </div>
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </div>
          </div>
        </mat-autocomplete>
        <mat-spinner [diameter]="15" *ngIf="loading"></mat-spinner>
      </mat-form-field>

      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let entity of selectedEntities"
          selectable="true"
          removable="true"
          (removed)="removeEntity(entity.alpha_id)"
        >
          {{ entity.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="setup-footer">
    <button mat-button (click)="defaultPortfolio()">
      <span>Use Default</span>
    </button>
    <button [class.valid]="isValid()" mat-button (click)="createPortfolio()">
      <span>Create Portfolio</span>
    </button>
  </div>
</div>
