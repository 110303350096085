/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { YukkApi } from '../../../service/yukkapi.service';
import { RoutingService } from '../../../service/routing.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../../service/config.service';
import { ColorPipe } from '../../../pipe/color.pipe';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SupplyChainService } from './news-network.services/network-data.service';
import { SupplyChainData } from './network-data.interface';
import { takeUntil, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';

/**
 * The network use D3 collision animation: each node have a circle, a text and links connected with another nodes
 * If you look for D3 Force Directed Graph on the web you can find a lot of examples : {@link https://bl.ocks.org/heybignick/3faf257bbbbc7743bb72310d03b86ee8/|bl.ocks.org}
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
})
export class NetworkComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private isUpdatingFilters = false;
  loading: boolean;
  nodata: boolean;
  result: any;
  tag: any = undefined;
  targets: any;
  groupid: string;
  nodes: any;
  links: any;
  zoom: any;
  svg: any;
  simulation: any;
  custom: any = {
    size: 40,
    link: {
      color: 'white',
    },
  };
  params: any;
  previousValue: any;
  titolo: any;
  theme: string;
  error: boolean;
  supplyChain: SupplyChainData;
  fixedNodeX = null;
  fixedNodeY = null;

  /**
   * load the modules and service and setup the theme
   */
  constructor(
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    private colorpipe: ColorPipe,
    private router: Router,
    public auth: AuthService,
    public config: ConfigService,
    public snackBar: MatSnackBar,
    private supplyChainService: SupplyChainService,
  ) {
    this.theme = this.routing.theme;
  }
  ngOnInit(): void {
    combineLatest([
      this.route.queryParams.pipe(
        distinctUntilChanged((prev, curr) => {
          const relevantParams = [
            'type',
            'id',
            'time',
            'lang',
            'feed',
            'categories',
            'continents',
            'countries',
            'ranks',
            'panels',
            'newstype',
            'sup_prod',
            'sup_geo',
            'sup_tier',
            'sup_score',
          ];
          return !this.routing.reFresh(curr, prev, relevantParams);
        }),
      ),
      this.supplyChainService.supplyChain$.pipe(
        filter((data) => data !== null),
      ),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([params, supplyChain]) => ({ params, supplyChain })),
      )
      .subscribe({
        next: ({ params, supplyChain }) => {
          if (!this.isUpdatingFilters) {
            this.params = params;

            this.handleParamsChange(params, supplyChain, this.loading);
          }
        },
        error: (error) => {
          console.error('Error in data stream:', error);
          this.error = true;
          this.loading = false;
        },
      });

    // Subscribe to loading state changes
    this.supplyChainService.loading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.loading = isLoading;
      });
  }

  private handleParamsChange(
    params: any,
    supplyChain: SupplyChainData | null,
    reloadSupliers: boolean,
  ) {
    this.updateThemeAndCustomization(params);

    if (this.shouldRefreshData(params) || reloadSupliers) {
      this.error = false;
      this.nodata = false;

      if (supplyChain === null) {
        this.error = true;
        return;
      }

      const { motherCompany, suppliers } = supplyChain;
      this.supplyChain = { motherCompany, suppliers };
      this.processSupplyChainData(params);
    } else if (
      this.routing.reFresh(this.params, this.previousValue, ['reflow'])
    ) {
      setTimeout(() => {
        this.refresh();
      }, 500);
    } else {
      this.mynetwork();
    }

    this.previousValue = { ...params };
  }

  private shouldRefreshData(params: any): boolean {
    const relevantParams = [
      'type',
      'id',
      'time',
      'lang',
      'feed',
      'categories',
      'continents',
      'countries',
      'ranks',
      'panels',
      'newstype',
      'sup_prod',
      'sup_geo',
      'sup_tier',
      'sup_score',
    ];
    return this.routing.reFresh(params, this.previousValue, relevantParams);
  }

  private updateThemeAndCustomization(params: any) {
    if (
      !localStorage.getItem('theme') ||
      localStorage.getItem('theme') === null ||
      localStorage.getItem('theme') === 'dark'
    ) {
      this.custom.link.color = 'white';
    }
    if (params.theme === 'light' || localStorage.getItem('theme') === 'light') {
      this.custom.link.color = 'black';
    }

    if (params.tag) {
      this.tag = decodeURI(params.tag.split('|')[0]);
    } else {
      this.tag = undefined;
    }
  }

  /**
   * when the component is destroy we have to sto the d3 simulation
   */
  ngOnDestroy() {
    if (this.simulation) {
      this.simulation.stop();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * initialize the d3 network
   */
  iNitial() {
    if (this.result.network.nodes.length) {
      this.nodata = false;
    } else {
      this.nodata = true;
    }
    this.loading = false;
    this.nodes = this.result.network.nodes;
    this.links = this.result.network.edges;

    const mincount = Math.min(...this.nodes.map((nodeao) => nodeao.score));
    const maxcount = Math.max(...this.nodes.map((nodeae) => nodeae.score));
    const mincount2 = Math.min(
      ...this.nodes
        .filter((el) => el.sentiment !== null)
        .map((nodeao) => nodeao.sentiment),
    );
    const maxcount2 = Math.max(
      ...this.nodes
        .filter((el) => el.sentiment !== null)
        .map((nodeae) => nodeae.sentiment),
    );
    const mouseOver = () => {
      if (!this.routing.isMobile() && !this.tag) {
        return (d) => {
          node.style('fill-opacity', (o) => {
            const thisOpacity = isConnected(d, o) ? 1 : 0.5;
            return thisOpacity;
          });
          link.style('stroke-opacity', (o) =>
            o.source === d || o.target === d ? 0.5 : 0.05,
          );
        };
      }
    };

    const mouseOut = () => {
      if (!this.tag) {
        node.style('fill-opacity', 1);
        link.style('stroke-opacity', 0.15);
      }
    };
    const circleradio = d3
      .scaleLinear()
      .domain([maxcount2, mincount2])
      .range([50, 80]);
    const fontsize = d3
      .scaleLinear()
      .domain([mincount, maxcount])
      .range([3, 25]);
    const minweight = Math.min(...this.links.map((edge) => edge.weight));
    const maxweight = Math.max(...this.links.map((edge) => edge.weight));
    const edgeweight = d3
      .scaleLinear()
      .domain([minweight, maxweight])
      .range([1, 2]);

    this.isTag();

    const links_data = this.links.map((link1) => {
      return {
        source: link1.source,
        target: link1.target,
        weight: edgeweight(link1.weight),
      };
    });

    const nodes_data = this.nodes.map((node1) => {
      return {
        tag: node1.tag,
        name: node1.name,
        group: node1.group,
        radius: node1.sentiment ? circleradio(node1.sentiment) : 50,
        fontsize: fontsize(node1.score),
        sentiment: node1.sentiment,
        color:
          node1.metadata && node1.metadata === 'product'
            ? '#89C4FA'
            : this.colorpipe.transform(node1.sentiment),
        fx: node1.posX ? node1.posX : null,
        fy: node1.posY ? node1.posY : null,
      };
    });

    const width = document.querySelector('#graph').clientWidth;
    const height = document.querySelector('#graph').clientHeight;

    d3.select('#graph svg').remove();
    this.svg = d3
      .select('#graph')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('cursor', 'move');

    const g = this.svg.append('g');

    const links = g.append('g').attr('class', 'cont_link');

    const link = links
      .selectAll('node')
      .data(links_data)
      .enter()
      .append('path')
      .attr('class', 'links')
      .attr('fill', 'transparent')
      .attr('stroke-width', (d) => d.weight)
      .attr('stroke', this.custom.link.color)
      .attr('stroke-opacity', () => '0.15');

    const nodes = g.append('g').attr('class', 'nodes');

    const node = nodes
      .selectAll('node')
      .data(nodes_data)
      .classed('fixed', (d) => d.score > 10)
      .enter()
      .append('g')
      .on('mouseover', mouseOver())
      .on('mouseout', mouseOut);

    const circle = node
      .append('circle')
      .attr('class', 'circle')
      .attr('r', (d) => d.radius)
      .attr('fill', (d) => d.color)
      .style('cursor', (d) => {
        if (d.group === 2) {
          return undefined;
        } else {
          return 'pointer';
        }
      });

    node
      .append('text')
      .attr('class', 'text')
      .attr('text-anchor', 'middle')
      .style('cursor', 'pointer')
      .attr('font-weight', (d) => {
        if (
          d.name &&
          d.tag === 'm:' + this.supplyChain.motherCompany.entity.compound_key
        ) {
          return 'bold';
        }
      })
      .attr('font-size', (d) => {
        if (
          d.name &&
          d.tag !== 'm:' + this.supplyChain.motherCompany.entity.compound_key
        ) {
          const maxcount1 = Math.max(
            ...d.name.split(' ').map((word) => word.length),
          );
          if (maxcount1 * d.fontsize > d.radius * 3) {
            return '12px';
          }
          return '12px';
        } else if (
          d.name &&
          d.tag === 'm:' + this.supplyChain.motherCompany.entity.compound_key
        ) {
          return '20px';
        }
      })
      .attr('cx', function (d) {
        return d.x;
      })
      .attr('cy', function (d) {
        return d.y;
      })
      .html((d) => {
        if (d.name) {
          const arr = d.name
            .trim()
            .replace(/[^A-Za-z0-9 ]/g, '')
            .split(' ');
          if (arr.length > 3) {
            return (
              '<tspan x="0" y="' +
              -d.fontsize +
              '">' +
              arr.splice(0, Math.ceil(arr.length / 4)).join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              -d.fontsize / 3.5 +
              '">' +
              arr.splice(0, Math.ceil(arr.length / 3)).join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              d.fontsize / 3.5 +
              '">' +
              arr.splice(0, Math.ceil(arr.length / 2)).join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              d.fontsize +
              '">' +
              arr.join(' ') +
              '</tspan>'
            );
          } else if (arr.length > 2) {
            return (
              '<tspan x="0" y="' +
              (-d.fontsize / 3 - 10) +
              '">' +
              arr.splice(0, Math.ceil(arr.length / 3)).join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              (d.fontsize / 3 - 7) +
              '">' +
              arr.splice(0, Math.ceil(arr.length / 2)).join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              (d.fontsize / 1 - 5) +
              '">' +
              arr.join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              2 * d.fontsize +
              '" style="font-weight: bold">' +
              (d.sentiment ? (d.sentiment * 10).toFixed(1) : '—') +
              '</tspan>'
            );
          } else if (arr.length > 1) {
            return (
              '<tspan x="0" y="' +
              (-d.fontsize / 3 - 5) +
              '">' +
              arr.splice(0, Math.ceil(arr.length / 2)).join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              (d.fontsize / 2 - 5) +
              '">' +
              arr.join(' ') +
              '</tspan>' +
              '<tspan x="0" y="' +
              (2 * d.fontsize - 5) +
              '" style="font-weight: bold">' +
              (d.sentiment ? (d.sentiment * 10).toFixed(1) : '—') +
              '</tspan>'
            );
          } else {
            return (
              '<tspan x="0" y="' +
              (d.fontsize / 4 - 10) +
              '">' +
              d.name +
              '</tspan>' +
              '<tspan x="0" y="' +
              (2 * d.fontsize - 10) +
              '" style="font-weight: bold">' +
              (d.sentiment ? (d.sentiment * 10).toFixed(1) : '—') +
              '</tspan>'
            );
          }
        }
      });

    const simulation = d3
      .forceSimulation(nodes_data)
      .force(
        'collide',
        d3
          .forceCollide(
            (d: d3.SimulationNodeDatum & { radius: number }) =>
              d.radius + d.radius / 3 + 5,
          )
          .iterations(30),
      )
      .force('link', d3.forceLink(links_data))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('y', d3.forceY().strength(width / height));

    for (let i = 0; i < 300; ++i) {
      simulation.tick();
    }
    simulation.stop();

    node
      .attr('cx', (d) => {
        if (this.tag && d.group === 2) {
          d.x = Math.max(d.radius, Math.min(width - d.radius, d.x));
        }
      }) // forcing to stay within viewport's width
      .attr('cy', (d) => {
        if (this.tag && d.group === 2) {
          d.y = Math.max(d.radius, Math.min(height - d.radius, d.y));
        }
      }) // forcing to stay within viewport's height
      .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')')
      .on('click', (d) => (d.group === 2 ? undefined : click_tag(d)));

    link.attr('d', (d) => {
      const dx = d.target.x - d.source.x,
        dy = d.target.y - d.source.y,
        dr = Math.sqrt(dx * dx + dy * dy);
      return (
        'M' +
        d.source.x +
        ',' +
        d.source.y +
        'A' +
        dr +
        ',' +
        dr +
        ' 0 0,1 ' +
        d.target.x +
        ',' +
        d.target.y
      );
    });

    circle
      .style('opacity', (d) => {
        if (this.tag) {
          const productNodes = this.result.network.nodes
            .filter((n) => n.metadata && n.metadata === 'product')
            .map((el) => el.index);
          const sameProductSuppliersNodes = this.result.network.edges
            .filter((e) => productNodes.includes(e.target))
            .map((e) => e.source);
          if (
            this.tag === d.tag ||
            'm:' + this.supplyChain.motherCompany.entity.compound_key ===
              d.tag ||
            productNodes.includes(d.index) ||
            sameProductSuppliersNodes.includes(d.index)
          ) {
            return 1;
          } else {
            return 0.5;
          }
        } else {
          return 1;
        }
      })
      .style('stroke', (d) =>
        this.tag && this.tag === d.tag ? 'white' : 'none',
      )
      .style('stroke-width', (d) =>
        this.tag && this.tag === d.tag ? '2px' : '0px',
      );

    link.attr('stroke-opacity', (d) => {
      const productNodes = this.result.network.nodes
        .filter((n) => n.metadata && n.metadata === 'product')
        .map((el) => el.index);

      if (
        this.tag &&
        !(
          'm:' + this.supplyChain.motherCompany.entity.compound_key ===
          this.tag
        )
      ) {
        if (
          this.tag === d.source.tag ||
          this.tag === d.target.tag ||
          productNodes.includes(d.target.index)
        ) {
          return '0.5';
        } else {
          return '0.05';
        }
      } else {
        return '0.15';
      }
    });

    link.style('stroke-dasharray', (d) => {
      const productNodes = this.result.network.nodes
        .filter((n) => n.metadata && n.metadata === 'product')
        .map((el) => el.index);

      if (
        this.tag &&
        !(
          'm:' + this.supplyChain.motherCompany.entity.compound_key ===
          this.tag
        )
      ) {
        if (productNodes.includes(d.target.index)) {
          return '5, 5';
        } else {
          return 'none';
        }
      } else {
        return 'none';
      }
    });

    link.attr('class', () => 'sup-links-animated');
    const drag_start = (d: d3.SimulationNodeDatum) => {
      if (!d3.event.active) {
        simulation.alphaTarget(0.3).restart();
      }
      d.fx = d.x;
      d.fy = d.y;
      d3.event.sourceEvent.stopPropagation();
    };

    const drag_drag = (d) => {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    };

    const drag_end = (d) => {
      if (!d3.event.active) {
        simulation.alphaTarget(0);
      }
      d.fx = null;
      d.fy = null;
    };
    if (!this.routing.isMobile()) {
      const drag_handler = d3
        .drag()
        .on('start', drag_start)
        .on('drag', drag_drag)
        .on('end', drag_end);
      drag_handler(node);
    }

    const click_tag = (d) => {
      this.goTag(d);
    };

    this.zoom = d3.zoom().on('zoom', () => {
      g.attr('transform', d3.event.transform);
    });
    this.svg.call(this.zoom);

    const linkedByIndex = {};
    links_data.forEach((d) => {
      linkedByIndex[d.source.index + ',' + d.target.index] = 1;
    });

    const isConnected = (a, b) => {
      return (
        linkedByIndex[a.index + ',' + b.index] ||
        linkedByIndex[b.index + ',' + a.index] ||
        a.index === b.index
      );
    };
  }

  /**
   * chekc the tag active
   */
  isTag() {
    this.targets = [];
    this.groupid = undefined;
    if (this.tag) {
      this.nodes.forEach((node) => {
        if (node.tag === this.tag) {
          this.groupid = node.group;
        }
      });
      this.links.forEach((link) => {
        if (link.source === this.groupid || link.target === this.groupid) {
          this.targets.push(link.target);
          this.targets.push(link.source);
        }
      });
    }
  }

  /**
   * filter the circles
   */
  filterCircle(d) {
    if (this.tag) {
      if (this.tag === d.tag) {
        return true;
      }
      if (this.targets.indexOf(d.group) !== -1) {
        return true;
      }
      return false;
    }
    if (this.params.filter === 'pos') {
      return d.sentiment > 0.6;
    }
    if (this.params.filter === 'neu') {
      return d.sentiment >= 0.4 && d.sentiment <= 0.6;
    }
    if (this.params.filter === 'neg') {
      return d.sentiment < 0.6;
    }
    return true;
  }

  /**
   * filter the links
   */
  filterLink(d) {
    if (this.tag) {
      if (
        (this.targets.indexOf(d.target.group) !== -1 &&
          d.source.group === this.groupid) ||
        (this.targets.indexOf(d.source.group) !== -1 &&
          d.target.group === this.groupid)
      ) {
        return true;
      }
      return false;
    }
    if (this.params.filter === 'pos') {
      return d.source.sentiment > 0.6 && d.target.sentiment > 0.6;
    }
    if (this.params.filter === 'neu') {
      return (
        d.source.sentiment >= 0.4 &&
        d.source.sentiment <= 0.6 &&
        d.target.sentiment >= 0.4 &&
        d.target.sentiment <= 0.6
      );
    }
    if (this.params.filter === 'neg') {
      return d.source.sentiment < 0.4 && d.target.sentiment < 0.4;
    }
    return true;
  }

  /**
   * update the d3 network
   */
  mynetwork() {
    this.isTag();
    d3.selectAll('.circle').attr('opacity', (d) =>
      this.filterCircle(d) ? 1 : 0.2,
    );
    d3.selectAll('.links').attr('opacity', (d) =>
      this.filterLink(d) ? 1 : 0.1,
    );
    d3.selectAll('text').attr('fill', 'black');
  }

  /**
   * refresh the network
   */
  refresh() {
    this.iNitial();
  }

  /**
   * zoom inside the network
   */
  inzoom(value) {
    this.zoom.scaleBy(this.svg, value);
  }

  /**
   * click a node
   */
  goTag(d) {
    let mytag = d.tag + '|' + d.name;
    if (this.params.tag === mytag) {
      mytag = null;
      this.router.navigate([], {
        queryParams: {
          portfolioId: null,
          type: null,
          id: 'suppliers_portfolio',
          tag: mytag,
          news: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      this.fixedNodeX = d.x;
      this.fixedNodeY = d.y;
      this.router.navigate([], {
        queryParams: {
          portfolioId: 'suppliers_portfolio',
          type: 'company',
          id: d.tag.split(':')[2],
          tag: mytag,
          news: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  private processSupplyChainData(params) {
    this.yukkApi.isentiment(this.params, true).subscribe((result) => {
      if (
        !(this.auth.scorelabSettings.defaultScore === 'sentiment') &&
        this.params.type &&
        (this.params.type === 'company' || this.params.type === 'pne')
      ) {
        this.yukkApi
          .scoresTimeSeries('score', this.params, 'chart')
          .subscribe((result2) => {
            this.titolo = Object.assign({}, result, {
              score: Number(
                result2['score_ts'][result2['score_ts'].length - 1][
                  'score'
                ]?.toFixed(2),
              ),
            });
          });
      } else {
        this.titolo = result;
      }
    });
    const obj = {
      params: this.params,
      custom: {
        size: undefined,
      },
    };
    if (this.routing.isPath('mobile')) {
      obj.custom.size = 20;
    } else {
      obj.custom.size = 40;
    }

    this.result = {
      network: {
        nodes: [],
        edges: [],
      },
    };
    const payload = [];
    payload.push(this.supplyChain.motherCompany.entity.compound_key);
    this.supplyChain.suppliers.forEach((el) => {
      payload.push(el.entity.compound_key);
    });
    this.yukkApi
      .scoresTimeSeries(
        'score',
        Object.assign({}, params, {
          scoreForPortfolio: true,
          itemized: true,
          custom_payload: payload,
        }),
        'chart',
      )
      .subscribe((res) => {
        const resCopy = JSON.parse(JSON.stringify(res));
        const filteredPayload = [];
        let counter = 0;
        resCopy.forEach((el, index) => {
          if (index === 0) {
            this.result.network.nodes.push({
              index: 0,
              tag: 'm:' + this.supplyChain.motherCompany.entity.compound_key,
              name: this.supplyChain.motherCompany.entity.shortName,
              group: 0,
              score: 1,
              sentiment: el.score_ts_last_score.score
                ? el.score_ts_last_score.score / 10.0
                : null,
            });
            this.result.network.edges.push({
              source: 0,
              target: 0,
              weight: 1,
            });
            if (
              this.params.id ===
                this.supplyChain.motherCompany.entity.alpha_id ||
              this.params.id === 'suppliers_portfolio'
            ) {
              this.auth.selectedSupplierScore =
                el.score_ts_last_score.score?.toFixed(1);
            }
          } else {
            if (
              this.params.sup_prod ||
              this.params.sup_geo ||
              this.params.sup_tier ||
              this.params.sup_score
            ) {
              let isFilteredOut = false;
              if (
                this.params.sup_prod &&
                !this.supplyChain.suppliers[
                  index - 1
                ].supplied_products.includes(this.params.sup_prod)
              ) {
                isFilteredOut = true;
              }
              if (
                this.params.sup_geo &&
                !this.supplyChain.suppliers[index - 1].countries.includes(
                  this.params.sup_geo,
                )
              ) {
                isFilteredOut = true;
              }
              if (this.params.sup_score) {
                if (this.params.sup_score === '0-2') {
                  if (
                    !el.score_ts_last_score.score ||
                    el.score_ts_last_score.score > 2
                  ) {
                    isFilteredOut = true;
                  }
                } else if (this.params.sup_score === '2-4') {
                  if (
                    el.score_ts_last_score.score < 2 ||
                    el.score_ts_last_score.score > 4
                  ) {
                    isFilteredOut = true;
                  }
                } else if (this.params.sup_score === '4-6') {
                  if (
                    el.score_ts_last_score.score < 4 ||
                    el.score_ts_last_score.score > 6
                  ) {
                    isFilteredOut = true;
                  }
                } else if (this.params.sup_score === '6-8') {
                  if (
                    el.score_ts_last_score.score < 6 ||
                    el.score_ts_last_score.score > 8
                  ) {
                    isFilteredOut = true;
                  }
                } else if (this.params.sup_score === '8-10') {
                  if (el.score_ts_last_score.score < 8) {
                    isFilteredOut = true;
                  }
                }
              }
              if (!isFilteredOut) {
                filteredPayload.push(
                  this.supplyChain.suppliers[index - 1].entity.compound_key,
                );
                counter++;
                this.result.network.nodes.push({
                  index: counter,
                  tag:
                    'm:' +
                    this.supplyChain.suppliers[index - 1].entity.compound_key,
                  name: this.supplyChain.suppliers[index - 1].entity.shortName,
                  group: 1,
                  score: 1,
                  sentiment: el.score_ts_last_score.score
                    ? el.score_ts_last_score.score / 10.0
                    : null,
                });
                this.result.network.edges.push({
                  source: 0,
                  target: counter,
                  weight: 1,
                });
                if (
                  this.tag &&
                  this.tag ===
                    'm:' +
                      this.supplyChain.suppliers[index - 1].entity.compound_key
                ) {
                  this.supplyChain.suppliers[
                    index - 1
                  ].supplied_products.forEach((product) => {
                    this.result.network.nodes.push({
                      index: counter + 1,
                      tag: 'm:' + product,
                      name: product,
                      group: 2,
                      score: 1,
                      sentiment: null,
                      metadata: 'product',
                    });
                    this.result.network.edges.push({
                      source: counter,
                      target: counter + 1,
                      weight: 1,
                    });
                    counter++;
                  });
                }
              }
            } else {
              counter++;
              this.result.network.nodes.push({
                index: counter,
                tag:
                  'm:' +
                  this.supplyChain.suppliers[index - 1].entity.compound_key,
                name: this.supplyChain.suppliers[index - 1].entity.shortName,
                group: 1,
                score: 1,
                sentiment: el.score_ts_last_score.score
                  ? el.score_ts_last_score.score / 10.0
                  : null,
              });
              this.result.network.edges.push({
                source: 0,
                target: counter,
                weight: 1,
              });
              if (
                this.tag &&
                this.tag ===
                  'm:' +
                    this.supplyChain.suppliers[index - 1].entity.compound_key
              ) {
                this.supplyChain.suppliers[index - 1].supplied_products.forEach(
                  (product) => {
                    this.result.network.nodes.push({
                      index: counter + 1,
                      tag: 'm:' + product,
                      name: product,
                      group: 2,
                      score: 1,
                      sentiment: null,
                      metadata: 'product',
                    });
                    this.result.network.edges.push({
                      source: counter,
                      target: counter + 1,
                      weight: 1,
                    });
                    counter++;
                  },
                );
              }
            }
            if (
              this.params.id ===
              this.supplyChain.suppliers[index - 1].entity.alpha_id
            ) {
              this.auth.selectedSupplierScore =
                el.score_ts_last_score.score?.toFixed(1);
            }
          }
        });
        if (
          this.tag &&
          this.tag !== 'm:' + this.supplyChain.motherCompany.entity.compound_key
        ) {
          this.supplyChain.suppliers
            .filter(
              (supplier) => 'm:' + supplier.entity.compound_key === this.tag,
            )?.[0]
            ?.supplied_products.forEach((product) => {
              let productIndex = null;
              const productSuppliers = [];
              const sameProductSuppliers = this.supplyChain.suppliers
                .filter((s) => s.supplied_products.includes(product))
                .map((s) => 'm:' + s.entity.compound_key);
              this.result.network.nodes.forEach((node) => {
                if (
                  node.metadata &&
                  node.metadata === 'product' &&
                  node.name === product
                ) {
                  productIndex = node.index;
                }
                if (sameProductSuppliers.includes(node.tag)) {
                  productSuppliers.push(node.index);
                }
              });
              productSuppliers.forEach((ps) => {
                this.result.network.edges.push({
                  source: ps,
                  target: productIndex,
                  weight: 1,
                });
              });
            });
        }
        if (this.tag && this.fixedNodeX && this.fixedNodeY) {
          this.result.network.nodes.forEach((node, i) => {
            if (node.tag === this.tag && !node.metadata) {
              this.result.network.nodes[i].posX = this.fixedNodeX;
              this.result.network.nodes[i].posY = this.fixedNodeY;
            }
          });
        }

        this.auth.suppliersPortfolio = undefined;
        const portfolio = {
          uid: 'suppliers_portfolio',
          name: 'Suppliers',
          content: [],
        };

        if (
          this.params.sup_prod ||
          this.params.sup_geo ||
          this.params.sup_tier ||
          this.params.sup_score
        ) {
          filteredPayload.forEach((supplier) => {
            portfolio.content.push(supplier);
          });
          if (portfolio.content.length === 0) {
            this.snackBar.open(
              'No suppliers meeting selected criteria.',
              'OK',
              { duration: 5000 },
            );
          }
        } else {
          this.supplyChain.suppliers.forEach((supplier) => {
            portfolio.content.push(supplier.entity.compound_key);
          });
        }
        this.auth.suppliersPortfolio = portfolio;

        if (!this.tag) {
          this.router.navigate([], {
            queryParams: {
              update: Math.random(),
            },
            queryParamsHandling: 'merge',
            replaceUrl: false,
          });
        }

        this.iNitial();
      });
  }
}
