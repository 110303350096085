import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { PortfolioParams } from '../models/params.interface';
import { Entity } from '../models/entity.interface';
import { SentimentItemizedResponse } from '../models/sentiment.interface';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  constructor(private yukkaApi: YukkApi) {}

  getPortfolio(
    iparams: PortfolioParams,
    folio: string[],
  ): Observable<{
    treemap: {
      children: { entity: Entity; sentiment: SentimentItemizedResponse }[];
    };
  }> {
    return this.yukkaApi
      .getEntitiesInfo(folio, 'array', iparams.getSanctions ? true : false)
      .pipe(
        mergeMap((entities: Entity[]) => {
          const content = entities.map((item: Entity) => item.compound_key);
          return this.yukkaApi
            .sentimentItemized(iparams, content)
            .pipe(
              map((sentiments) => this.constructTreemap(entities, sentiments)),
            );
        }),
      );
  }

  private constructTreemap(
    entities: Entity[],
    sentiments: SentimentItemizedResponse,
  ): {
    treemap: {
      children: { entity: Entity; sentiment: SentimentItemizedResponse }[];
    };
  } {
    const treemapObject = {
      treemap: { children: [] },
    };

    entities.forEach((entity) => {
      const entityData = {
        entity,
        ...sentiments[entity.compound_key],
      };
      treemapObject.treemap.children.push(entityData);
    });

    return treemapObject;
  }
}
