/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, ObservableInput, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { SupplyChainService } from 'src/app/cockpit/main-news/news-network/news-network.services/network-data.service';

/**
 * redirect to specific portfolio if no one is selected and store the data in the auth service
 */

@Injectable()
export class FolioGuard {
  constructor(
    private auth: AuthService,
    public config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private supplyChainService: SupplyChainService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const currentUrl = state.url.split('?')[0];
    if (
      currentUrl === '/cockpit/news/portfolio/scanner' &&
      this.auth.featureFlags.defaultPortfolioView !== 'scanner'
    ) {
      this.router.navigate(
        [
          '/cockpit/news/portfolio/' +
            this.auth.featureFlags.defaultPortfolioView,
        ],
        {
          replaceUrl: true,
        },
      );
    } else {
      return this.auth.portFolios().pipe(
        switchMap((foliosArg): ObservableInput<any> => {
          let folios = JSON.parse(JSON.stringify(foliosArg));
          if (currentUrl === '/cockpit/news/portfolio/network') {
            return this.supplyChainService.getSupplyChain().pipe(
              map((supplyChain) => {
                const suppliers = supplyChain?.suppliers;
                if (this.auth.suppliersPortfolio) {
                  folios = [this.auth.suppliersPortfolio];
                } else {
                  folios = [
                    {
                      uid: 'suppliers_portfolio',
                      name: 'Suppliers',
                      content: [],
                    },
                  ];
                  suppliers.forEach((supplier) => {
                    folios[0].content.push(supplier.entity.compound_key);
                  });
                }
                this.handleFolioNavigation(folios, state, currentUrl);
                return of(true);
              }),
            );
          } else {
            this.handleFolioNavigation(folios, state, currentUrl);
            return of(true);
          }
        }),
      );
    }
  }

  private handleFolioNavigation(folios, state, currentUrl) {
    this.auth.folios = [];
    this.auth.folio = undefined;
    if (folios[0]) {
      this.auth.folios = folios;
      if (!state.root.queryParams.id && !state.root.queryParams.name) {
        const sortedFolios = JSON.parse(JSON.stringify(folios));
        sortedFolios.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.auth.folio = sortedFolios[0];
        this.router.navigate([currentUrl], {
          queryParams: {
            id: this.auth.folio.uid,
            news: state.root.queryParams.news,
            highlight: state.root.queryParams.highlight,
            time: state.root.queryParams.time,
            filter: state.root.queryParams.filter,
            lang: state.root.queryParams.lang
              ? state.root.queryParams.lang
              : 'en,de',
            feed: state.root.queryParams.feed
              ? state.root.queryParams.feed
              : 'non_professional,online,print,professional',
            ranks: state.root.queryParams.ranks,
            panels: state.root.queryParams.panels,
            // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
            categories: state.root.queryParams.categories
              ? state.root.queryParams.categories
              : '',
            continents: state.root.queryParams.continents,
            countries: state.root.queryParams.countries,
            factuality: state.root.queryParams.factuality,
            temporality: state.root.queryParams.temporality,
            event_ids: state.root.queryParams.event_ids,
            tutorial: state.root.queryParams.tutorial,
            sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment')
              ? 'score'
              : '-senti',
            context: state.root.queryParams.context,
          },
          // replaceUrl: true,
        });
      } else if (!state.root.queryParams.id && state.root.queryParams.name) {
        this.auth.folio = {
          email: null,
          uid: null,
          name: state.root.queryParams.name,
          content: state.root.queryParams.entities.split(','),
        };
      } else {
        if (!state.root.queryParams.portfolioId) {
          let idExists = false;
          folios.map((folio) => {
            if (folio.uid === state.root.queryParams.id) {
              this.auth.folio = folio;
              idExists = true;
            }
          });
          if (!idExists) {
            const sortedFolios = JSON.parse(JSON.stringify(folios));
            sortedFolios.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.auth.folio = sortedFolios[0];
            this.router.navigate([currentUrl], {
              queryParams: {
                id: this.auth.folio.uid,
                news: state.root.queryParams.news,
                highlight: state.root.queryParams.highlight,
                time: state.root.queryParams.time,
                filter: state.root.queryParams.filter,
                lang: state.root.queryParams.lang
                  ? state.root.queryParams.lang
                  : 'en,de',
                feed: state.root.queryParams.feed
                  ? state.root.queryParams.feed
                  : 'non_professional,online,print,professional',
                ranks: state.root.queryParams.ranks,
                panels: state.root.queryParams.panels,
                // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                categories: state.root.queryParams.categories
                  ? state.root.queryParams.categories
                  : '',
                continents: state.root.queryParams.continents,
                countries: state.root.queryParams.countries,
                factuality: state.root.queryParams.factuality,
                temporality: state.root.queryParams.temporality,
                event_ids: state.root.queryParams.event_ids,
                tutorial: state.root.queryParams.tutorial,
                sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment')
                  ? 'score'
                  : '-senti',
                context: state.root.queryParams.context,
              },
              // replaceUrl: true,
            });
          }
        } else {
          let idExists = false;
          folios.map((folio) => {
            if (folio.uid === state.root.queryParams.portfolioId) {
              this.auth.folio = folio;
              idExists = true;
            }
          });
          if (!idExists) {
            alert(
              'The link includes a portfolio that is not accessible. You will be redirected to your cockpit.',
            );
            const sortedFolios = JSON.parse(JSON.stringify(folios));
            sortedFolios.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.auth.folio = sortedFolios[0];
            this.router.navigate([currentUrl], {
              queryParams: {
                portfolioId: this.auth.folio.uid,
                // id: state.root.queryParams.id,
                id: null,
                type: state.root.queryParams.type,
                news: state.root.queryParams.news,
                highlight: state.root.queryParams.highlight,
                time: state.root.queryParams.time,
                filter: state.root.queryParams.filter,
                lang: state.root.queryParams.lang
                  ? state.root.queryParams.lang
                  : 'en,de',
                feed: state.root.queryParams.feed
                  ? state.root.queryParams.feed
                  : 'non_professional,online,print,professional',
                ranks: state.root.queryParams.ranks,
                panels: state.root.queryParams.panels,
                // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                categories: state.root.queryParams.categories
                  ? state.root.queryParams.categories
                  : '',
                continents: state.root.queryParams.continents,
                countries: state.root.queryParams.countries,
                factuality: state.root.queryParams.factuality,
                temporality: state.root.queryParams.temporality,
                event_ids: state.root.queryParams.event_ids,
                tutorial: state.root.queryParams.tutorial,
                sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment')
                  ? 'score'
                  : '-senti',
                context: state.root.queryParams.context,
              },
              // replaceUrl: true,
            });
          }
        }
      }
    }
  }
}
