/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { portfolioEvents } from './portfolio-events';
import * as moment from 'moment/moment';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-portfolio-setup',
  templateUrl: './portfolio-setup.component.html',
  styleUrls: ['./portfolio-setup.component.scss'],
})
export class PortfolioSetupComponent implements OnInit {
  params: any;
  portfolioName: string;
  searchFormControl = new FormControl();
  selectedEntities = [];
  items$: Observable<any>;
  loading: boolean;

  constructor(
    public auth: AuthService,
    private yukkApi: YukkApi,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.portfolioName = '';
    this.selectedEntities = [];
    this.searchFormControl.setValue('');
    this.searchFormControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => {
        if (value && value.length > 1) {
          this.loading = true;
          this.items$ = this.yukkApi.search(value, false).pipe(
            mergeMap((result) => {
              if (result && result.hits.length === 0) {
                const words = value.split(' ').filter((item) => item !== '');
                return this.yukkApi
                  .search(words[0] ? words[0] : ' ', false)
                  .pipe(
                    map((result2) => {
                      this.loading = false;
                      return result2;
                    }),
                  );
              } else {
                this.loading = false;
                return of(result);
              }
            }),
          );
        }
      });
  }

  optSelect(option) {
    this.searchFormControl.setValue('');

    this.selectedEntities.push({
      name: option.entity.name,
      type: option.entity.type,
      alpha_id: option.entity.alpha_id,
    });
  }

  removeEntity(id) {
    this.selectedEntities = this.selectedEntities.filter(
      (e) => e.alpha_id !== id,
    );
  }

  iClose() {
    this.dialog.closeAll();
  }

  iMatch(entity) {
    if (entity.matched_form) {
      return (
        entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0)
      );
    }
  }

  numberFormat(num) {
    // eslint-disable-next-line
    // @ts-ignore
    return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
  }

  isValid() {
    if (this.portfolioName?.trim() && this.selectedEntities?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  createPortfolio() {
    if (this.isValid()) {
      this.auth.portFolios().subscribe((result) => {
        if (result && result.length > 0) {
          this.auth.deletePortfolio(result[0].uid).subscribe();
        }
      });
      this.auth.newFolio(this.portfolioName).subscribe((result) => {
        this.auth
          .addEntitiesToPortfolios(
            this.selectedEntities.map((entity) => {
              return entity.type + ':' + entity.alpha_id;
            }),
            [result.id],
          )
          .subscribe(() => {
            this.generateNewsletter(result.id, this.portfolioName);
            this.generateAlert(result.id, this.portfolioName);
            this.generateReport(this.portfolioName, this.selectedEntities.map((entity) => {
              return entity.type + ':' + entity.alpha_id;
            }));
            this.iClose();
            this.snackBar.open(
              'We are generating your default portfolio, preparing the weekly newsletter, the weekly report and the daily alert...',
              'OK',
              { duration: 5000 },
            );
          });
      });
    }
  }

  generateNewsletter(id, name) {
    const newsletter = {
      active: true,
      contents: ['top_news'],
      delivery_settings: {
        frequency: 'WEEKLY',
      },
      dispObjects: [
        {
          payload: id,
          type: 'portfolio',
          view: name,
        },
      ],
      displayables: ['ylrn:displayable:portfolio:' + id],
      frequency: 'WEEKLY',
      header_image:
        'https://yl-newsletters-logos-v1.s3.amazonaws.com/Logo_YUKKA_News-Analytics_Transparent.png',
      hide_sentiment_bars: false,
      logo: '',
      news_settings: {
        top_news_max_number: 20,
      },
      recipients: [],
      stories_max_number_default: 10,
      title: name + ' - Newsletter',
      top_news_max_number: 20,
      top_news_max_number_default: 7,
      uid: '',
    };
    this.auth.editNewsletter(newsletter).subscribe((result2) => {
      if (result2 && result2.uid) {
        const data = {
          uid: result2.uid,
        };
        this.auth.generateNewsletter(data).subscribe();
      }
    });
  }

  generateAlert(id, name) {
    let alertEvents = [];
    if (this.auth.accountInfo.interest === 'esg') {
      alertEvents = portfolioEvents.esgLab;
    } else if (this.auth.accountInfo.interest === 'risk') {
      alertEvents = portfolioEvents.riskLab;
    } else if (this.auth.accountInfo.interest === 'news') {
      alertEvents = portfolioEvents.newsLab;
    } else if (this.auth.accountInfo.interest === 'invest') {
      alertEvents = portfolioEvents.investLab;
    }
    const payloadObject = {
      active: true,
      schedule: '1d',
      channel: 'email',
      layout: 'digest',
      name: 'Alert for ' + name,
      additional_recipients: [],
      event_alert_metadata: {
        portfolio_id: id,
        entities: [],
        event_ids: alertEvents,
        threshold: 5,
        threshold_setting: 'z_score',
        additional_params: {},
      },
    };
    this.auth.addAlert(payloadObject).subscribe();
  }

  generateReport(name, content) {
    let type = undefined;
    if (this.auth.accountInfo.interest === 'esg') {
      type = 'esg';
    } else if (this.auth.accountInfo.interest === 'risk') {
      type = 'general_risk';
    } else if (this.auth.accountInfo.interest === 'news') {
      type = 'general_risk';
    } else if (this.auth.accountInfo.interest === 'invest') {
      type = 'general_risk';
    }
    const date = moment(new Date()).format('YYYY-MM-DD');
    if (type) {
      this.auth.sendReportPortfolio(name, content, type, date).subscribe();
    }
  }

  defaultPortfolio() {
    this.auth.portFolios().subscribe((result) => {
      this.generateNewsletter(result[0].uid, result[0].name);
      this.generateAlert(result[0].uid, result[0].name);
      this.generateReport(result[0].name, result[0].content);
      this.iClose();
      this.snackBar.open(
        'We are generating your default portfolio, preparing the weekly newsletter, the weekly report and the daily alert...',
        'OK',
        { duration: 5000 },
      );
    });
  }
}
