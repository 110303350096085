<ng-container *ngIf="trigger.isDeltaAlert">

  <div class="subalert-delta-line">

    <span>Notify me when the score</span>

    <mat-form-field class="field-delta-alert direction">
      <mat-select [(ngModel)]="trigger.additional_params.z_score_direction" required>
        <mat-option [value]="'pos'">increases by</mat-option>
        <mat-option [value]="'neg'">decreases by</mat-option>
        <mat-option [value]="'both'">changes by</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="field-delta-alert value" *ngIf="(trigger.score_type !== 'sentiment')">
      <mat-label>Value...</mat-label>
      <input type="number"
             maxlength="2"
             min="0"
             max="1.5"
             step="0.1"
             required
             matInput
             [(ngModel)]="trigger.additional_params.z_score_threshold"
             (change)= "onOutOfRange(trigger, 'score')"
             name="threshold">
      <mat-error>Please enter a value between 0 and 1.5</mat-error>
    </mat-form-field>

    <mat-form-field class="field-delta-alert value" *ngIf="(trigger.score_type === 'sentiment')">
      <mat-label>Value...</mat-label>
      <input type="number"
             maxlength="3"
             min="0"
             max="15"
             step="1"
             required
             matInput
             [(ngModel)]="trigger.additional_params.z_score_threshold"
             (change)= "onOutOfRange(trigger, 'sentiment')"
             name="threshold">
      <mat-error>Please enter a value between 0 and 15</mat-error>
    </mat-form-field>

  </div>

  <div class="subalert-delta-line">
    <span class="delta-alert-header">Alert for consecutive thresholds</span>
    <mat-icon matTooltip="If the delta threshold is met on consecutive days, select your preffered alert frequency">info</mat-icon>
  </div>

  <div class="subalert-delta-line">
    <mat-radio-group class="group-delta-frequency" [(ngModel)]="trigger.additional_params.plateau_period">
      <mat-radio-button value="0">Once</mat-radio-button>
      <mat-radio-button value="1">Daily</mat-radio-button>
      <mat-radio-button value="selectedDeltaPeriod">
        Every
        <mat-select [(ngModel)]="selectedDeltaPeriod" [disabled]="[0, 1].includes(trigger.additional_params.plateau_period)">
          <mat-option *ngFor="let period of deltaPeriods" [value]="period">{{period}}</mat-option>
        </mat-select>
        days
      </mat-radio-button>
    </mat-radio-group>
  </div>

</ng-container>
