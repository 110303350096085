<div
  class="news-title-container"
  *ngIf="titolo"
  [class.notitle]="params.notitle === 'true'"
  [class.score-focus]="
    (routing.isChart() || routing.isNetwork()) &&
    (routing.isFolio() || routing.isMarket())
  "
>
  <div
    class="news-title"
    *ngIf="titolo && !routing.isNetwork()"
    [class.notitle]="params.notitle === 'true'"
    [class.score-focus]="
      routing.isChart() && (routing.isFolio() || routing.isMarket())
    "
  >
    <div class="cont">
      <div class="cont-left">
        <ng-container *ngIf="routing.isWidget() && !routing.isFolio()">
          <div class="text">
            <span class="tipo"
              ><span style="font-weight: normal"
                >{{ titolo.entity.type | nicetxt: false }}:</span
              >
              {{ titolo.entity.name | nicetxt: false }}</span
            >
            <!--<mat-icon class="sanctions-alert-icon" *ngIf="titolo.entity.sanctions.length > 0" matTooltip="Sanctioned Entity. Click Icon for detailed Sanction Lists Data." (click)="$event.stopPropagation(); openDialog(titolo.entity);">warning</mat-icon>-->
            <span
              class="alert-container"
              *ngIf="
                titolo.entity.sanctions?.length > 0 &&
                auth.featureFlags.showSanctions
              "
            >
              <mat-icon
                class="sanctions-alert-icon"
                svgIcon="alert"
                matTooltip="Sanctioned Entity. Click Icon for detailed Sanction Lists Data."
                (click)="$event.stopPropagation(); openDialog(titolo.entity)"
                [class.theme-light]="theme === 'light'"
              ></mat-icon>
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="routing.isMarket()">
          <div
            class="select"
            *ngIf="
              params.type &&
              (params.type === 'company' ||
                params.type === 'country' ||
                params.type === 'pne') &&
              auth.featureFlags.addEntities
            "
            [matMenuTriggerFor]="menu"
            matTooltip="Add entity to portfolio"
          >
            <mat-icon>add</mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="appendItem('add')">
              Add entity to existing portfolios
            </button>
            <button mat-menu-item (click)="appendItem('create')">
              Add entity to a new portfolio
            </button>
          </mat-menu>
          <div class="text">
            <span class="tipo"
              ><span style="font-weight: normal"
                >{{ titolo.entity.type | nicetxt: false }}:</span
              >
              {{ titolo.entity.name | nicetxt: false }}</span
            >
            <!--<mat-icon class="sanctions-alert-icon" *ngIf="titolo.entity.sanctions.length > 0" matTooltip="Sanctioned Entity. Click Icon for detailed Sanction Lists Data." (click)="$event.stopPropagation(); openDialog(titolo.entity);">warning</mat-icon>-->
            <span
              class="alert-container"
              *ngIf="
                titolo.entity.sanctions?.length > 0 &&
                auth.featureFlags.showSanctions
              "
            >
              <mat-icon
                class="sanctions-alert-icon"
                svgIcon="alert"
                matTooltip="Sanctioned Entity. Click Icon for detailed Sanction Lists Data."
                (click)="$event.stopPropagation(); openDialog(titolo.entity)"
                [class.theme-light]="theme === 'light'"
              ></mat-icon>
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="routing.isFolio()">
          <div
            *ngIf="!routing.isWidget()"
            class="select"
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"
            matTooltip="Select entity"
          >
            <mat-icon *ngIf="!menuTrigger.menuOpen">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="menuTrigger.menuOpen">arrow_drop_up</mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="select('overall')">Overall</button>
            <button
              mat-menu-item
              (click)="select(entity)"
              *ngFor="let entity of entities | orderBy: 'name'"
            >
              {{ entity.type | nicetxt: false }} :
              {{ entity.name | nicetxt: false }}
            </button>
          </mat-menu>
          <div class="text">
            <span class="tipo"
              ><span style="font-weight: normal"
                >{{ titolo.entity.type | nicetxt: false }}:</span
              >
              {{ titolo.entity.name | nicetxt: false }}</span
            >
            <!--<mat-icon class="sanctions-alert-icon" *ngIf="titolo.entity.sanctions.length > 0" matTooltip="Sanctioned Entity. Click Icon for detailed Sanction Lists Data." (click)="$event.stopPropagation(); openDialog(titolo.entity);">warning</mat-icon>-->
            <span
              class="alert-container"
              *ngIf="
                titolo.entity.sanctions?.length > 0 &&
                auth.featureFlags.showSanctions
              "
            >
              <mat-icon
                class="sanctions-alert-icon"
                svgIcon="alert"
                matTooltip="Sanctioned Entity. Click Icon for detailed Sanction Lists Data."
                (click)="$event.stopPropagation(); openDialog(titolo.entity)"
                [class.theme-light]="theme === 'light'"
              ></mat-icon>
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="routing.isQuery()">
          <div
            class="select"
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"
            matTooltip="Select query"
          >
            <mat-icon *ngIf="!menuTrigger.menuOpen">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="menuTrigger.menuOpen">arrow_drop_up</mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="select('overall')">Overall</button>
            <button
              mat-menu-item
              (click)="select(queryItem)"
              *ngFor="let queryItem of queries | orderBy: 'name'"
            >
              {{ queryItem.name }}
            </button>
          </mat-menu>
          <div
            class="text"
            matTooltip="{{ titolo?.entity.name | nicetxt: false }}"
          >
            <span class="tipo"
              ><span style="font-weight: normal"
                >{{
                  params.id === params.groupId ? 'Collection' : 'Query'
                }}:</span
              >
              {{ auth.query?.name }}</span
            >
          </div>
        </ng-container>

        <!--<ng-container *ngIf="(routing.isFolio() && !params.portfolioId)">
          <div class="text">
            <span>Portfolio:</span>
            <mat-select class="ioselect" [(ngModel)]="folio" (selectionChange)="inFolio($event.value)">
              <mat-option *ngFor="let folio of folios" [value]="folio.name">{{folio.name}}</mat-option>
            </mat-select>
          </div>
        </ng-container>-->

        <!--<ng-container *ngIf="routing.isQuery()">
          <div class="text" matTooltip="{{titolo.entity.name|nicetxt: false}}">Query: {{auth.query?.name}}</div>
        </ng-container>-->

        <ng-container
          *ngIf="routing.isSearch() && auth.featureFlags.addQueries"
        >
          <div
            class="select"
            [matMenuTriggerFor]="menu"
            matTooltip="Add query to collection"
          >
            <mat-icon>add</mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="appendItem('add')">
              Add query to existing collections
            </button>
            <button mat-menu-item (click)="appendItem('create')">
              Add query to a new collection
            </button>
          </mat-menu>
          <div
            class="text"
            matTooltip="{{ titolo.entity.name | nicetxt: false }}"
          >
            Query: {{ titolo.entity.name | nicetxt: false }}
          </div>
        </ng-container>

        <!--<ng-container *ngIf="(auth.scorelabSettings.defaultScore === 'credit_risk') && params.type && ((params.type === 'company') || (params.type === 'pne')) && selectedSector">
          <div *ngIf="!routing.isWidget()" class="select" #menuTriggerSector="matMenuTrigger" [matMenuTriggerFor]="menuSector" matTooltip="Select the sector this company belongs to">
            <mat-icon *ngIf="!menuTriggerSector.menuOpen">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="menuTriggerSector.menuOpen">arrow_drop_up</mat-icon>
          </div>
          <mat-menu #menuSector="matMenu">
            <button mat-menu-item (click)="selectSector(sector)" *ngFor="let sector of sectors">{{sector.name|nicetxt: false}}</button>
          </mat-menu>
          <div class="text">
            <span class="tipo"><span style="font-weight: normal;">{{'Sector'}}:</span> {{selectedSector.name|nicetxt: false}}</span>
          </div>
        </ng-container>-->
      </div>

      <ng-container
        *ngIf="
          !(
            titolo &&
            routing.isChart() &&
            (routing.isFolio() || routing.isMarket())
          )
        "
      >
        <div
          *ngIf="
            !params.custom_score &&
            auth.scorelabSettings.defaultFlag &&
            showWarningFlag
          "
          class="cont-right"
        >
          <div
            class="info flag warning"
            [matTooltip]="getFlagTooltip('warning')?.tooltip"
          >
            <mat-icon>flag</mat-icon>
          </div>
        </div>

        <div
          *ngIf="
            !params.custom_score &&
            auth.scorelabSettings.defaultFlag &&
            !showWarningFlag &&
            showCautionFlag
          "
          class="cont-right"
        >
          <div
            class="info flag caution"
            [matTooltip]="getFlagTooltip('caution')?.tooltip"
          >
            <mat-icon>flag</mat-icon>
          </div>
        </div>

        <!--<div *ngIf="routing.isChart() && (auth.scorelabSettings.defaultScore === 'esg') && (industryBenchmarkScore !== null) && params.type && (params.type === 'company')" class="cont-right">
          <div class="info bench">
            <span>{{industryBenchmarkDescription}}</span>
          </div>
        </div>-->

        <div
          *ngIf="
            [
              'esg',
              'eb_sim',
              'eb_sim_risk',
              'general_risk',
              'esg_risk',
              'immediate_risk',
              'credit_risk',
              'bb',
            ].includes(this.customScore) &&
            industryBenchmarkScore !== null &&
            params.type &&
            params.type === 'company'
          "
          class="cont-right"
        >
          <div
            class="info flag bench"
            [matTooltip]="industryBenchmarkDescription"
          >
            <mat-icon
              *ngIf="industryBenchmarkScore === 0"
              svgIcon="timer-sand-full"
              class="laggard"
            ></mat-icon>
            <mat-icon
              *ngIf="industryBenchmarkScore === 1"
              svgIcon="trending-down"
              class="below"
            ></mat-icon>
            <mat-icon
              *ngIf="industryBenchmarkScore === 2"
              svgIcon="trending-up"
              class="above"
            ></mat-icon>
            <mat-icon
              *ngIf="industryBenchmarkScore === 3"
              svgIcon="medal"
              class="leader"
            ></mat-icon>
          </div>
        </div>

        <div class="cont-right">
          <div
            class="info"
            *ngIf="
              (!titolo.hasOwnProperty('score') && !(titolo.score === null)) ||
              routing.isQuery()
            "
            [style.color]="titolo.sentiment.sentiment | color"
          >
            <span>{{ titolo.sentiment.sentiment | percent }}</span>
            <div class="trendicon">
              <span class="brak">(</span>
              <span>{{
                titolo.sentiment.sentiment_delta * 100 | round | trend
              }}</span>
              <span>{{
                titolo.sentiment.sentiment_delta * 100 | round | negative
              }}</span>
              <span class="brak">)</span>
            </div>
          </div>

          <div class="info" *ngIf="titolo.score === null && !routing.isQuery()">
            <span>--</span>
          </div>
          <div
            class="info"
            *ngIf="
              titolo.hasOwnProperty('score') &&
              !['bb'].includes(this.customScore) &&
              !routing.isQuery()
            "
            [style.color]="
              auth.getScoreType() === 'pos'
                ? (titolo.score | colorScore2)
                : (titolo.score | colorScore)
            "
          >
            <span>{{ titolo.score }}</span>
          </div>
          <div
            class="info"
            *ngIf="
              titolo.hasOwnProperty('score') &&
              ['bb'].includes(this.customScore) &&
              !(['bb'].includes(this.customScore) && routing.isFolio()) &&
              !routing.isQuery()
            "
          >
            <span class="icon">
              <img
                *ngIf="titolo.trend_type === 'bear'"
                src="assets/icon/bear.svg"
              />
              <img
                *ngIf="titolo.trend_type === 'bull'"
                src="assets/icon/bull.svg"
              />
            </span>
            <span>{{ titolo.trend_value }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    class="news-title news-title-scores"
    *ngIf="
      titolo && routing.isChart() && (routing.isFolio() || routing.isMarket())
    "
    [class.notitle]="params.notitle === 'true'"
    [class.score-focus]="
      routing.isChart() && (routing.isFolio() || routing.isMarket())
    "
  >
    <div
      *ngIf="
        !config.appConfig.setup.reportsDisabled &&
        [
          'general_risk',
          'immediate_risk',
          'esg',
          'esg_risk',
          'eb_sim',
          'eb_sim_risk',
          'credit_risk',
        ].includes(this.customScore) &&
        (titolo?.entity?.type === 'company' ||
          titolo?.entity?.type === 'portfolio') &&
        isReportAvailable
      "
      class="score-box report"
    >
      <button
        mat-flat-button
        [matTooltip]="
          'Get a PDF summary on top ' +
          auth.getScoreName().name +
          ' Stories, Score Trends and Peer-Group Benchmarks.'
        "
        (click)="openReportDialog(titolo.entity)"
      >
        <span>Report</span>
        <mat-icon>picture_as_pdf</mat-icon>
        <span class="info-label" *ngIf="titolo?.entity?.type === 'portfolio'"
          >BETA</span
        >
      </button>
    </div>

    <div
      #scoreBoxWarningFlag
      *ngIf="auth.scorelabSettings.defaultFlag && showWarningFlag"
      class="score-box warning"
      [matTooltip]="getFlagTooltip('warning')?.tooltip"
      [class.score-focused]="
        mouseOverWarningFlag ||
        auth.scorelabSettings.defaultFlag.score_type === params?.score
      "
      (mouseover)="mouseOverWarningFlag = true"
      (mouseout)="mouseOverWarningFlag = false"
      (click)="
        onScoreFocus(
          'warning',
          this.auth.scorelabSettings.defaultFlag.score_type,
          flagWarningEventsTimeframe,
          scoreBoxWarningFlag,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span class="score-icon warning">
          <mat-icon>flag</mat-icon>
        </span>
        <span>{{
          getFlagTooltip('warning')?.score_threshold +
            ' ' +
            getFlagTooltip('warning')?.score_name
        }}</span>
      </div>
      <div class="score-info-cont">
        <span *ngIf="flagWarningEventsCount !== null"
          >{{ flagWarningEventsCount | thousandSuff }} events in previous
          {{ flagWarningEventsTimeframe }} days</span
        >
        <span
          *ngIf="flagWarningEventsCount === null && flagWarningEventsTimeframe"
          >previous {{ flagWarningEventsTimeframe }} days</span
        >
      </div>
    </div>

    <div
      #scoreBoxCautionFlag
      *ngIf="
        auth.scorelabSettings.defaultFlag && !showWarningFlag && showCautionFlag
      "
      class="score-box caution"
      [matTooltip]="getFlagTooltip('caution')?.tooltip"
      [class.score-focused]="
        mouseOverCautionFlag ||
        auth.scorelabSettings.defaultFlag.score_type === params?.score
      "
      (mouseover)="mouseOverCautionFlag = true"
      (mouseout)="mouseOverCautionFlag = false"
      (click)="
        onScoreFocus(
          'caution',
          this.auth.scorelabSettings.defaultFlag.score_type,
          flagCautionEventsTimeframe,
          scoreBoxCautionFlag,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span class="score-icon caution">
          <mat-icon>flag</mat-icon>
        </span>
        <span>{{
          getFlagTooltip('warning')?.score_threshold +
            ' ' +
            getFlagTooltip('warning')?.score_name
        }}</span>
      </div>
      <div class="score-info-cont">
        <span *ngIf="flagCautionEventsCount !== null"
          >{{ flagCautionEventsCount | thousandSuff }} events in previous
          {{ flagCautionEventsTimeframe }} days</span
        >
        <span
          *ngIf="flagCautionEventsCount === null && flagCautionEventsTimeframe"
          >previous {{ flagCautionEventsTimeframe }} days</span
        >
      </div>
    </div>

    <div
      *ngIf="
        [
          'esg',
          'eb_sim',
          'eb_sim_risk',
          'general_risk',
          'esg_risk',
          'immediate_risk',
          'credit_risk',
          'bb',
        ].includes(this.customScore) &&
        industryBenchmarkScore !== null &&
        params.type &&
        params.type === 'company'
      "
      class="score-box"
      [matTooltip]="industryBenchmarkDescription"
    >
      <div *ngIf="industryBenchmarkScore === 0" class="score-name-cont bench">
        <span class="score-icon laggard">
          <mat-icon
            *ngIf="industryBenchmarkScore === 0"
            svgIcon="timer-sand-full"
          ></mat-icon>
        </span>
        <span>{{ industryBenchmarkDescription }}</span>
      </div>
      <div *ngIf="industryBenchmarkScore === 1" class="score-name-cont bench">
        <span class="score-icon below">
          <mat-icon svgIcon="trending-down"></mat-icon>
        </span>
        <span>{{ industryBenchmarkDescription }}</span>
      </div>
      <div *ngIf="industryBenchmarkScore === 2" class="score-name-cont bench">
        <span class="score-icon above">
          <mat-icon svgIcon="trending-up"></mat-icon>
        </span>
        <span>{{ industryBenchmarkDescription }}</span>
      </div>
      <div *ngIf="industryBenchmarkScore === 3" class="score-name-cont bench">
        <span class="score-icon leader">
          <mat-icon svgIcon="medal"></mat-icon>
        </span>
        <span>{{ industryBenchmarkDescription }}</span>
      </div>
      <div class="score-info-cont">
        <span></span>
      </div>
    </div>

    <div
      #scoreBoxScoreSent
      class="score-box score-box-focus"
      *ngIf="!titolo.hasOwnProperty('score') && !(titolo.score === null)"
      [style.border-color]="titolo.sentiment.sentiment | color"
      [class.score-focused]="
        mouseOverScore || this.customScore === params?.score
      "
      (mouseover)="mouseOverScore = true"
      (mouseout)="mouseOverScore = false"
      (click)="
        onScoreFocus(
          'score',
          this.customScore,
          scoreEventsTimeframe,
          scoreBoxScoreSent,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span
          class="score-icon"
          [style.color]="titolo.sentiment.sentiment | color"
        >
          <!--<img src="assets/icon/o_general_risk.svg">-->
          <svg-icon src="assets/icon/o_sentiment.svg"></svg-icon>
        </span>
        <span>Sentiment</span>
        <span
          class="score-value"
          [style.color]="titolo.sentiment.sentiment | color"
          >{{ titolo.sentiment.sentiment | percent }}</span
        >
        <span
          class="score-trend"
          [style.color]="titolo.sentiment.sentiment | color"
          >({{ titolo.sentiment.sentiment_delta * 100 | round | trend
          }}{{
            titolo.sentiment.sentiment_delta * 100 | round | negative
          }})</span
        >
      </div>
      <div class="score-info-cont">
        <span *ngIf="scoreEventsCount !== null"
          >{{ scoreEventsCount | thousandSuff }} events in previous
          {{ scoreEventsTimeframe }} days</span
        >
        <span *ngIf="scoreEventsCount === null && scoreEventsTimeframe"
          >previous {{ scoreEventsTimeframe }} days</span
        >
      </div>
    </div>

    <div
      #scoreBoxScore1
      class="score-box score-box-focus"
      *ngIf="
        titolo.hasOwnProperty('score') && !['bb'].includes(this.customScore)
      "
      [style.border-color]="
        auth.getScoreType() === 'pos'
          ? (titolo.score | colorScore2)
          : (titolo.score | colorScore)
      "
      [class.score-focused]="
        mouseOverScore || this.customScore === params?.score
      "
      (mouseover)="mouseOverScore = true"
      (mouseout)="mouseOverScore = false"
      (click)="
        onScoreFocus(
          'score',
          this.customScore,
          scoreEventsTimeframe,
          scoreBoxScore1,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span
          class="score-icon"
          *ngIf="
            this.customScore &&
            !['eb_sim', 'eb_sim_risk'].includes(this.customScore)
          "
          [style.color]="
            auth.getScoreType() === 'pos'
              ? (titolo.score | colorScore2)
              : (titolo.score | colorScore)
          "
        >
          <!--<img src="assets/icon/o_general_risk.svg">-->
          <svg-icon
            src="{{ 'assets/icon/o_' + this.customScore + '.svg' }}"
          ></svg-icon>
        </span>
        <span>{{ auth.getScoresInfo(this.customScore)['name'] }}</span>
        <span
          class="score-value"
          [style.color]="
            auth.getScoreType() === 'pos'
              ? (titolo.score | colorScore2)
              : (titolo.score | colorScore)
          "
          >{{ titolo.score }}</span
        >
      </div>
      <div class="score-info-cont">
        <span *ngIf="scoreEventsCount !== null"
          >{{ scoreEventsCount | thousandSuff }} events in previous
          {{ scoreEventsTimeframe }} days</span
        >
        <span *ngIf="scoreEventsCount === null && scoreEventsTimeframe"
          >previous {{ scoreEventsTimeframe }} days</span
        >
      </div>
    </div>

    <div
      #scoreBoxScore2
      class="score-box score-box-focus"
      *ngIf="
        titolo.hasOwnProperty('score') &&
        ['bb'].includes(this.customScore) &&
        !routing.isQuery()
      "
      [style.border-color]="
        titolo.trend_type === 'bear' ? '#dd001a' : '#1e816c'
      "
      [class.score-focused]="
        mouseOverScore || this.customScore === params?.score
      "
      (mouseover)="mouseOverScore = true"
      (mouseout)="mouseOverScore = false"
      (click)="
        onScoreFocus(
          'score',
          this.customScore,
          scoreEventsTimeframe,
          scoreBoxScore2,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span
          class="score-icon bb-icon"
          *ngIf="this.customScore"
          [style.color]="titolo.trend_type === 'bear' ? '#dd001a' : '#1e816c'"
        >
          <!--<img src="assets/icon/o_general_risk.svg">-->
          <img
            *ngIf="titolo.trend_type === 'bear'"
            src="assets/icon/bear.svg"
          />
          <img
            *ngIf="titolo.trend_type === 'bull'"
            src="assets/icon/bull.svg"
          />
          <!--<svg-icon *ngIf="titolo.trend_type === 'bear'" src="{{'assets/icon/o_bear.svg'}}"></svg-icon>
          <svg-icon *ngIf="titolo.trend_type === 'bull'" src="{{'assets/icon/o_bull.svg'}}"></svg-icon>-->
        </span>
        <span>{{ auth.getScoresInfo(this.customScore)['name'] }}</span>
        <span
          class="score-value"
          [style.color]="titolo.trend_type === 'bear' ? '#dd001a' : '#1e816c'"
          >{{ titolo.trend_value }}</span
        >
      </div>
      <div class="score-info-cont">
        <span *ngIf="scoreEventsCount !== null"
          >{{ scoreEventsCount | thousandSuff }} events in previous
          {{ scoreEventsTimeframe }} days</span
        >
        <span *ngIf="scoreEventsCount === null && scoreEventsTimeframe"
          >previous {{ scoreEventsTimeframe }} days</span
        >
      </div>
    </div>

    <div
      #alertBoxScoreSent
      class="score-box score-box-focus"
      *ngIf="alertScoreType && alertScoreType === 'sentiment'"
      [style.border-color]="titolo.sentiment.sentiment | color"
      [class.score-focused]="mouseOverAlert || alertScoreType === params?.score"
      (mouseover)="mouseOverAlert = true"
      (mouseout)="mouseOverAlert = false"
      (click)="
        onScoreFocus(
          'alert',
          alertScoreType,
          alertEventsTimeframe,
          alertBoxScoreSent,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span
          class="score-icon"
          [style.color]="titolo.sentiment.sentiment | color"
        >
          <!--<img src="assets/icon/o_general_risk.svg">-->
          <svg-icon src="assets/icon/o_sentiment.svg"></svg-icon>
        </span>
        <span>Sentiment</span>
        <span
          class="score-value"
          [style.color]="titolo.sentiment.sentiment | color"
          >{{ titolo.sentiment.sentiment | percent }}</span
        >
        <span
          class="score-trend"
          [style.color]="titolo.sentiment.sentiment | color"
          >({{ titolo.sentiment.sentiment_delta * 100 | round | trend
          }}{{
            titolo.sentiment.sentiment_delta * 100 | round | negative
          }})</span
        >
      </div>
      <div class="score-info-cont">
        <span *ngIf="alertEventsCount !== null"
          >{{ alertEventsCount | thousandSuff }} events in previous
          {{ alertEventsTimeframe }} days</span
        >
        <span *ngIf="alertEventsCount === null && alertEventsTimeframe"
          >previous {{ alertEventsTimeframe }} days</span
        >
      </div>
    </div>

    <div
      #alertBoxScore1
      class="score-box score-box-focus"
      *ngIf="alertScoreType && !['sentiment', 'bb'].includes(alertScoreType)"
      [style.border-color]="
        auth.getScoreType(alertScoreType) === 'pos'
          ? (alertScoreValue | colorScore2)
          : (alertScoreValue | colorScore)
      "
      [class.score-focused]="mouseOverAlert || alertScoreType === params?.score"
      (mouseover)="mouseOverAlert = true"
      (mouseout)="mouseOverAlert = false"
      (click)="
        onScoreFocus(
          'alert',
          alertScoreType,
          alertEventsTimeframe,
          alertBoxScore1,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span
          class="score-icon"
          *ngIf="
            alertScoreType &&
            !['eb_sim', 'eb_sim_risk'].includes(alertScoreType)
          "
          [style.color]="
            auth.getScoreType(alertScoreType) === 'pos'
              ? (alertScoreValue | colorScore2)
              : (alertScoreValue | colorScore)
          "
        >
          <!--<img src="assets/icon/o_general_risk.svg">-->
          <svg-icon
            src="{{ 'assets/icon/o_' + alertScoreType + '.svg' }}"
          ></svg-icon>
        </span>
        <span>{{ auth.getScoresInfo(alertScoreType)['name'] }}</span>
        <span
          class="score-value"
          [style.color]="
            auth.getScoreType(alertScoreType) === 'pos'
              ? (alertScoreValue | colorScore2)
              : (alertScoreValue | colorScore)
          "
          >{{ alertScoreValue }}</span
        >
      </div>
      <div class="score-info-cont">
        <span *ngIf="alertEventsCount !== null"
          >{{ alertEventsCount | thousandSuff }} events in previous
          {{ alertEventsTimeframe }} days</span
        >
        <span *ngIf="alertEventsCount === null && alertEventsTimeframe"
          >previous {{ alertEventsTimeframe }} days</span
        >
      </div>
    </div>

    <div
      #alertBoxScore2
      class="score-box score-box-focus"
      *ngIf="
        alertScoreValue && alertScoreType && ['bb'].includes(alertScoreType)
      "
      [style.border-color]="
        alertScoreValue.trend_type === 'bear' ? '#dd001a' : '#1e816c'
      "
      [class.score-focused]="mouseOverAlert || alertScoreType === params?.score"
      (mouseover)="mouseOverAlert = true"
      (mouseout)="mouseOverAlert = false"
      (click)="
        onScoreFocus(
          'score',
          alertScoreType,
          alertEventsTimeframe,
          alertBoxScore2,
          true
        )
      "
    >
      <div class="score-name-cont">
        <span
          class="score-icon bb-icon"
          *ngIf="alertScoreType"
          [style.color]="
            alertScoreValue.trend_type === 'bear' ? '#dd001a' : '#1e816c'
          "
        >
          <!--<img src="assets/icon/o_general_risk.svg">-->
          <img
            *ngIf="alertScoreValue.trend_type === 'bear'"
            src="assets/icon/bear.svg"
          />
          <img
            *ngIf="alertScoreValue.trend_type === 'bull'"
            src="assets/icon/bull.svg"
          />
          <!--<svg-icon *ngIf="titolo.trend_type === 'bear'" src="{{'assets/icon/o_bear.svg'}}"></svg-icon>
          <svg-icon *ngIf="titolo.trend_type === 'bull'" src="{{'assets/icon/o_bull.svg'}}"></svg-icon>-->
        </span>
        <span>{{ auth.getScoresInfo(alertScoreType)['name'] }}</span>
        <span
          class="score-value"
          [style.color]="
            alertScoreValue.trend_type === 'bear' ? '#dd001a' : '#1e816c'
          "
          >{{ alertScoreValue.trend_value }}</span
        >
      </div>
      <div class="score-info-cont">
        <span *ngIf="alertEventsCount !== null"
          >{{ alertEventsCount | thousandSuff }} events in previous
          {{ alertEventsTimeframe }} days</span
        >
        <span *ngIf="alertEventsCount === null && alertEventsTimeframe"
          >previous {{ alertEventsTimeframe }} days</span
        >
      </div>
    </div>
  </div>
</div>
